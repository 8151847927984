// InteriorDesign.js
import React from "react";
import { ReactComponent as Frame1HomeBuilders } from "./Images/Frame 1 HomeBuilders.svg";
import { ReactComponent as Frame2HomeBuilders } from "./Images/Frame 2 HomeBuilders.svg";
import { ReactComponent as Frame3HomeBuilders } from "./Images/Frame 3 HomeBuilders.svg";
import ArrowIcon from "./Icons/ArrowIcon.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function InteriorDesign() {
  return (
    <div>
      <div>
        <section className="section-first-wrapper">
          <section className="section-first">
            <div className="section-first-text-container">
              <h1 className="section-first-title">
                Pedra for Interior Designers
              </h1>
              <div className="section-first-text">
                A tool for Interior Designers to help them start and sell
                interior design projects.
              </div>
              <div className="section-first-button-container">
                <button className="button-small" onClick={redirectToPage}>
                  Try Pedra <ArrowIcon />
                </button>
              </div>
            </div>
          </section>
        </section>

        <div style={{ height: "100px", borderTop: "1px solid #EEEEEE" }}></div>

        <section className="project project-alt">
          <div className="picture">
            <Frame1HomeBuilders />
          </div>

          <div className="description">
            <h2> Fast floor planning </h2>

            <p className="value-prop-text">
              Forget about hard to use software like AutoCAD or Revit.
            </p>
            <p className="value-prop-text">
              Pedra is made to make floor planning as easy as pen and paper –
              get everything done in 5min!
            </p>
          </div>
        </section>

        <div className="long-break"> </div>

        <section className="project project-alt">
          <div className="picture">
            <Frame2HomeBuilders />
          </div>

          <div className="description">
            <h2> Quickly calculate budgets </h2>

            <p className="value-prop-text">
              Track the budget of each change: new wall, new floor, new chair,
              etc.
            </p>
            <p className="value-prop-text">
              Display prices directly on the canvas for easier client
              communication.
            </p>
          </div>
        </section>

        <div className="long-break"> </div>

        <section className="project project-alt">
          <div className="picture">
            <Frame3HomeBuilders />
          </div>

          <div className="description">
            <h2> Generate any image in seconds </h2>

            <p className="value-prop-text">
              Your customer want to visualize home design or renovation ideas,
              but 3D renders take long and cost lots of money.
            </p>
            <p className="value-prop-text">
              With Pedra's AI features you can visualize an empty room, a
              renovation, or a wall color change at the click of a button.
            </p>
          </div>
        </section>

        <div className="long-break"> </div>
        <div className="centered-heading">
          <button className="button" onClick={redirectToPage}>
            Try Pedra
          </button>
        </div>
      </div>
    </div>
  );
}

export default InteriorDesign;
