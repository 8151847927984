import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

function HowToRenovate() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to Use the Renovation Tool to Virtually Renovate Homes
          </h1>

          <div className="title-secondary gray">
            Tutorial on How to Virtually Renovate Homes
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              What are Pedra's virtual renovation tools for?
            </h2>
            <p className="article-text">
              With Pedra's renovation tools, you can display a potential
              renovation of a property in just a few seconds.
              <p style={{ marginTop: "10px" }}>
                Showing the potential of a renovation is ideal for helping sell
                second-hand properties, which will likely need a good investment
                from the buyer to improve their condition.
              </p>
              <p style={{ marginTop: "10px" }}>
                Thanks to Pedra, there’s no need to hire renderers or architects
                to get a renovation proposal for a property.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              How do I create a virtual renovation proposal?
            </h2>
            <p className="article-text">
              Once you’ve uploaded the images you want to renovate, select them.
              <p style={{ marginTop: "10px" }}>
                Click on{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span>, and
                you’ll see the options for{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span> and{" "}
                <span style={{ fontWeight: "500" }}>"Advanced Renovation"</span>
                .
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="How to Use the Renovation Tool to Virtually Renovate Homes – Pedra Software Screenshot"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Renovation: </span>
              Generate a Quick Virtual Renovation
            </h2>
            <p style={{ marginTop: "10px" }}>
              By clicking on{" "}
              <span style={{ fontWeight: "500" }}>"Renovation"</span>, you’ll
              generate a renovation proposal for the images you’ve selected.
              You’ll have it in about 15 seconds.
              <p>
                By default, the{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span> option
                generates a minimalist renovation with a low level of change.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="How to Use the Renovation Tool to Virtually Renovate Homes – Pedra Software Screenshot"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Advanced Renovation: </span>
              Generate a Virtual Renovation to Your Liking
            </h2>
            <p className="article-text">
              Pedra also offers the "Advanced Renovation" option.
              <p style={{ marginTop: "10px" }}>
                Like the <span style={{ fontWeight: "500" }}>"Renovation"</span>{" "}
                option, you can generate renovation proposals in seconds.
                <p style={{ marginTop: "10px" }}>
                  Additionally, you can choose the renovation style, the level
                  of creativity for the changes, and even whether to preserve
                  certain parts of the original image, such as the windows.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="How to Use the Renovation Tool to Virtually Renovate Homes – Pedra Software Screenshot"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              {" "}
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 1 – </span>
              Advanced renovation style
            </h3>
            <p className="article-text">
              Pedra offers 9 basic styles of advanced renovation: minimalist,
              traditional, Mediterranean, etc.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  You can also upload your own renovation style.
                </span>{" "}
                By uploading your own renovation style you will be able to
                create renovation proposals based on the style you uploaded.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 2 – </span>
              Advanced renovation creativity
            </h3>
            <p className="article-text">
              You can also choose the level of advanced renovation creativity
              between Low, Medium, High, and Very High.
              <p style={{ marginTop: "10px" }}>
                Low and Medium will preserve the architecture of the original
                photograph well.
              </p>
              <p style={{ marginTop: "10px" }}>
                High and Very High are intended to change the space’s
                architecture aggressively, so the resulting photograph will not
                resemble the original.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 3 – </span>
              Preserve windows in advanced renovation
            </h3>
            <p className="article-text">
              You also have the option to preserve the windows when performing
              an advanced renovation. This is important if you want to maintain
              the views from the windows or if you want them to be exactly the
              same as in the original photograph.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 4 – </span>
              Generate advanced renovation image
            </h2>
            <p>
              Once you’ve selected the style, creativity level, and whether or
              not to preserve the windows, you can generate the images. It will
              take about 15 seconds per image, unless you preserve windows,
              which will take a bit longer.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  How to avoid unwanted changes in windows and doors
                </h4>
                <p className="callout-p">
                  If doors appear where there were windows, or if there are more
                  windows than expected in the generated images, we suggest the
                  following actions:
                  <ul>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Generate 2 or 3 additional renovations
                      </span>
                      . Each renovation is unique, so there will be some that
                      better preserve the windows and doors than others.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      {" "}
                      Keep the creativity level low.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        If the original photograph has reflections or mirrors
                        that resemble windows, use the tool to remove them from
                        the image
                      </span>
                      . Then, generate the renovation on the image without
                      reflections or mirrors.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenovate;
