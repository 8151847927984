import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeBigBrushIcon from "./Icons/OrangeBigBrushIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeCleanerIcon from "./Icons/OrangeCleanerIcon.jsx";
import OrangePaletteIcon from "./Icons/OrangePaletteIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import CheckIcon from "./Icons/CheckIcon.jsx";
import MagicWandIconPurple from "./Icons/MagicWandIconPurple.jsx";
import HammerIconPricing from "./Icons/HammerIconPricing.jsx";
import HDIconPricing from "./Icons/HDIconPricing.jsx";
import WallChangePricingIcon from "./Icons/WallChangePricingIcon.jsx";
import PhotographyPricingIcon from "./Icons/PhotographyIconPricing.jsx";
import VideoPricingIcon from "./Icons/VideoPricingIcon.jsx";
import RemoveObjectPricingIcon from "./Icons/RemoveObjectPricingIcon.jsx";
import FurnishPricingIcon from "./Icons/FurnishPricingIcon.jsx";
import EmptyIcon from "./Icons/EmptyIcon.jsx";
import OrangeBlueSky from "./Icons/OrangeBlueSky.jsx";

//Images
import HomeStagingPage1 from "./Images/HomeStagingPage1.png";
import HomeStagingPage2 from "./Images/HomeStagingPage2.png";
import HomeStagingPage3 from "./Images/HomeStagingPage3.png";
import HomeStagingPage4 from "./Images/HomeStagingPage4.png";
import HomeStagingPage5 from "./Images/HomeStagingPage5.png";
import HomeStagingPage6 from "./Images/HomeStagingPage6.png";
import HomeStagingPage7 from "./Images/HomeStagingPage7.png";
import RealEstatePhotographyEsPage6 from "./Images/RealEstatePhotographyEsPage6.jpeg";
import Bouchra from "./Images/Bouchra image.png";
import DavidCanoyra from "./Images/David Canoyra image.png";
import PaulaAharonian from "./Images/Paula Aharonian image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import RomainBury from "./Images/Romain Bury.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";
import MacarenaRico from "./Images/Macarena Rico image.png";
import NachoGarcia from "./Images/Nacho Garcia image.png";
import BrushIconPricing from "./Icons/BrushIconPricing.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

const structuredData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is home staging?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Home staging is a real estate marketing technique that involves preparing a property (house, apartment, etc.) for sale or rent with the aim of making it more attractive to potential buyers or tenants. This is achieved through strategic presentation and decoration of the space to highlight its most positive features and minimize its flaws.",
      },
    },
    {
      "@type": "Question",
      name: "What does virtual home staging include?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Virtual home staging uses digital tools to present a property attractively. It includes AI-powered image editing to retouch and enhance photographs, creating design renderings to visualize potential renovations, and offers time and cost savings compared to traditional home staging. It also allows for great flexibility and customization in property presentation.",
      },
    },
    {
      "@type": "Question",
      name: "What are the steps in the Virtual Home Staging process?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The virtual home staging process with Pedra includes three main steps: 1. Upload project photos. 2. Use Virtual Home Staging options in the 'Edit with AI' menu, which include emptying rooms, adding furniture, renovating spaces, etc. 3. Download the final Home Staging images once all modifications have been made.",
      },
    },
    {
      "@type": "Question",
      name: "Can Virtual Home Staging be done on empty properties?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes! Our virtual home staging tool allows you to furnish empty spaces. You can use the 'Furnish' option to automatically add furniture with AI, or manually add furniture with the 'Add object' option. These features help you visualize the potential of spaces, facilitating decision-making and improving property presentation.",
      },
    },
    {
      "@type": "Question",
      name: "What is the price of virtual home staging?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The price of Pedra is €29 plus VAT per month. With this payment, you can generate up to 400 images per month. There is no commitment, so you can cancel when you no longer need it.",
      },
    },
  ],
};

function VirtualHomeStaging() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  const [FAQ5, setFAQ5] = useState(false);
  const [FAQ6, setFAQ6] = useState(false);
  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">Virtual Home Staging</h1>
            <div className="section-first-text">
              <h2 className="section-first-text-bold">
                Use Pedra to create virtual home staging for your real estate
                properties.
              </h2>
              <h2 className="section-first-text-gray">
                Don't waste money or time – with Pedra, in just a few seconds
                and at a reasonable price, you can create the virtual home
                staging you need to sell.
              </h2>
            </div>
            <div className="section-first-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Try Pedra <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container – Virtual home staging – Testimonial">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Director of Photography at aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Real Estate Agent at Huspy – Virtual home staging – Testimonial"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO at Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO at Gilart Consultors – Virtual home staging – Testimonial"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  5,000+ real estate professionals already use Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <div className="header-video-frame">
              <div class="header-video-frame-circles-container">
                <div class="header-video-frame-circle"></div>
                <div class="header-video-frame-circle"></div>
                <div class="header-video-frame-circle"></div>
              </div>
              <video
                className="header-picture"
                width="100%"
                height="auto"
                playsInline
                autoPlay
                muted
                loop
                alt="Software for virtual home staging"
                style={{
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                <source
                  src="https://pedraimages.s3.eu-west-3.amazonaws.com/Pedra+website+demo+Jul+09+24.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MORE THAN 5,000 REAL ESTATE PROFESSIONALS ALREADY TRUST PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div className="section-one-column-left">
        <h2 className="title-container" style={{ display: "inline" }}>
          Virtual home staging in 1 click.{" "}
          <span className="text-highlight">
            Stop spending a lot of money and hours to adapt the presentation of
            your properties.
          </span>{" "}
          With Pedra, you can make the necessary changes yourself in seconds and
          get the virtual home staging that{" "}
          <span className="text-highlight">will help you sell</span>.{" "}
        </h2>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeFeatherIcon />
                <h2 className="feature-title">
                  Create virtual home staging by automatically furnishing
                </h2>
                <p className="feature-text">
                  Just choose the type of property and Pedra will take care of
                  placing the furniture for you.
                </p>
              </div>
              <img src={HomeStagingPage1} alt="Create virtual home staging" />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeCleanerIcon />
                <h2 className="feature-title">
                  Empty rooms virtually – in 1 click!
                </h2>
                <p className="feature-text">
                  Use Pedra's AI to magically empty rooms in just 1 click and
                  get the perfect virtual home staging. Sometimes you just need
                  to remove clutter and furniture from the photos.
                </p>
              </div>
              <img
                src={HomeStagingPage2}
                alt="Room emptying tool – virtual home staging"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangePaletteIcon />
                <h2 className="feature-title">
                  Select virtual home staging styles
                </h2>
                <p className="feature-text">
                  Choose from our various virtual home staging styles:
                  minimalist, Scandinavian, bohemian, etc. Pick the style that
                  best suits your potential client.
                </p>
              </div>
              <img
                src={HomeStagingPage3}
                alt="Select virtual home staging styles"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeBigBrushIcon />
                <h2 className="feature-title">
                  Change floors for your virtual home staging
                </h2>
                <p className="feature-text">
                  With Pedra, you can renovate that outdated parquet and make it
                  look in perfect condition. Or for those terraces with an
                  outdated floor, you can virtually renovate it.
                </p>
              </div>
              <img
                src={HomeStagingPage4}
                alt="Virtual floor change tool using virtual home staging"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h2 className="feature-title">
                  Virtual home staging with 3D pieces
                </h2>
                <p className="feature-text">
                  Use Pedra's 3D furniture library to create virtual home
                  staging to your liking. Add furniture, rotate it, make it
                  bigger, and click generate image to create the virtual home
                  staging.
                </p>
              </div>
              <img
                src={HomeStagingPage5}
                alt="Virtual home staging tool using AI – Software screenshot"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h2 className="feature-title">
                  Remove objects with AI for your virtual home staging
                </h2>
                <p className="feature-text">
                  Remove parts of an image before or after virtual home staging.
                </p>
              </div>
              <img
                src={HomeStagingPage6}
                alt="Remove Objects - Software screenshot"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeCleanerIcon />
                <h2 className="feature-title">
                  Depersonalize properties with ease
                </h2>
                <p className="feature-text">
                  Our virtual home staging software allows you to depersonalize
                  properties in a matter of seconds. This ensures that your
                  listings attract the maximum number of people on the main real
                  estate portals, improving your chances of selling.
                </p>
              </div>
              <img
                src={HomeStagingPage7}
                alt="Example of depersonalization with virtual home staging"
              />
            </div>
            <div className="feature-container-expander"></div>
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeBlueSky />
                <h2 className="feature-title">
                  Transform the sky with a click
                </h2>
                <p className="feature-text">
                  With our virtual home staging tools, you can change gray skies
                  to radiant blue ones in seconds. Create a positive atmosphere
                  that invites buyers to imagine sunny days in their future
                  home. Evoke emotions and accelerate your sales with perfect
                  skies!
                </p>
              </div>
              <img
                src={RealEstatePhotographyEsPage6}
                alt="Sky transformation using virtual home staging"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h3 className="title-container">
          Read some of the testimonials about our virtual home staging tool ↓
        </h3>
      </div>

      <div className="section-testimonials-container">
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={VictorLaviosa}
              alt="Victor Laviosa, Director of Photography at aProperties"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Victor Laviosa
              </div>
              <div className="testimonial-author-container-second-title">
                Director of Photography at aProperties
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Pedra is going great. I think the best thing about the tool is how
            quickly it generates the images.
          </q>
          <div className="testimonial-author-container-date">July 3, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Soma}
              alt="Francisco Somacarrera, Real Estate Agent at Huspy"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Francisco Somacarrera
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Agent at Huspy
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            🌈 🙌🏾 I've gone back to{" "}
            <a
              className="article-link"
              href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7211297450987270144?commentUrn=urn%3Ali%3Acomment%3A%28ugcPost%3A7211297450987270144%2C7212456394883555329%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287212456394883555329%2Curn%3Ali%3AugcPost%3A7211297450987270144%29"
              rel="nofollow"
            >
              making magic with Pedra
            </a>
            … Now it's time to wait!
          </q>
          <div className="testimonial-author-container-date">July 1, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={Bouchra}
              alt="Bouchra, Real Estate Advisor at iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Bouchra Benhammi
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Advisor at iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Pedra is the discovery of the century
          </q>
          <div className="testimonial-author-container-date">July 3, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DanieldelaMata}
              alt="Daniel de la Mata, CEO at Actívox"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Daniel de la Mata
              </div>
              <div className="testimonial-author-container-second-title">
                CEO at Actívox
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Pedra is being useful to us. We are very happy!!!!
            <div style={{ marginTop: "10px", display: "inline" }}>
              It should be more expensive.
            </div>
          </q>
          <div className="testimonial-author-container-date">May 28, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={RomainBury}
              alt="Romain Bury, Real Estate Agent at iad"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Romain Bury
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Agent at iad
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            Impressive.
            <div style={{ marginTop: "10px", display: "inline" }}>
              Plus, the specific space I wanted to empty seemed more complicated
              because it was deep, but it did a great job.
            </div>
          </q>
          <div className="testimonial-author-container-date">June 26, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={NachoGarcia}
              alt="Nacho García, Manager at Lucas Fox"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Nacho García
              </div>
              <div className="testimonial-author-container-second-title">
                Manager at Lucas Fox
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            A couple of houses have been sold thanks to Pedra.
            <div style={{ marginTop: "10px", display: "inline" }}>
              I placed proposals for how the terrace could look furnished in the
              cover photo and many people started clicking on the listing
            </div>
          </q>
          <div className="testimonial-author-container-date">June 7, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MariaJoseGilart}
              alt="Maria José Gilart, CEO at Gilart Consultors"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Maria José Gilart
              </div>
              <div className="testimonial-author-container-second-title">
                CEO at Gilart Consultors
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            I congratulate you, because it has improved a lot!!!
          </q>
          <div className="testimonial-author-container-date">June 2, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={EmanuelaCosma}
              alt="Emanuela Cosma"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Emanuela Cosma
              </div>
              <div className="testimonial-author-container-second-title">
                Real estate agent
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            With Pedra I managed to{" "}
            <a
              className="article-link"
              href="https://www.instagram.com/p/C4A6kCmIeMy/"
              rel="nofollow"
            >
              sell a €500k property
            </a>{" "}
            in less than a day. The apartment I had for sale was an apartment TO
            BE RENOVATED and with Pedra I got renders that allowed visualizing
            the potential of the spaces after renovation but without renovation!
          </q>
          <div className="testimonial-author-container-date">June 2, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={MacarenaRico}
              alt="Macarena Rico, Sales Dept. at Inmobiliaria Rico"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Macarena Rico
              </div>
              <div className="testimonial-author-container-second-title">
                Sales Dept. at Inmobiliaria Rico
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            I'm happy with the Pedra application.
          </q>
          <div className="testimonial-author-container-date">
            April 24, 2024
          </div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={PaulaAharonian}
              alt="Paula Aharonian, Real Estate Advisor at Monapart"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                Paula Aharonian
              </div>
              <div className="testimonial-author-container-second-title">
                Real Estate Advisor at Monapart
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">
            I'm very satisfied with the results
            <div style={{ marginTop: "10px", display: "inline" }}>
              Moreover, they've been improving the product based on the feedback
              I've shared!
            </div>
          </q>
          <div className="testimonial-author-container-date">June 4, 2024</div>
        </div>
        <div className="testimonial-container">
          <div className="testimonial-heading-container">
            <img
              className="testimonial-author-photo"
              src={DavidCanoyra}
              alt="David Canoyra, Inmobiliaria Encuentro, Boadilla del Monte"
            ></img>
            <cite className="testimonial-author-container">
              <div className="testimonial-author-container-title ">
                David Canoyra
              </div>
              <div className="testimonial-author-container-second-title">
                Inmobiliaria Encuentro, Boadilla del Monte
              </div>
            </cite>
          </div>
          <div className="testimonal-stars">★★★★★</div>
          <q className="testimonial-text">It's a good tool.</q>
          <div className="testimonial-author-container-date">May 6, 2024</div>
        </div>
      </div>

      <div className="section-one-column-left">
        <h2
          className="title-container"
          style={{ maxWidth: "400px", display: "inline" }}
        >
          Your virtual home staging needs covered for €29 per month. <br />{" "}
          <span className="text-highlight"> And without commitment.</span>{" "}
        </h2>
      </div>

      <div className="modal-product-tiers-container">
        <div className="modal-product-tier-current">
          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-1">
              Pro Plan
            </div>
            <div className="modal-product-plan-description-text-2-3-container">
              <div className="modal-product-plan-description-text-2">€29</div>
              <div className="modal-product-plan-description-text-3">
                per month
              </div>
            </div>
          </div>

          <div className="modal-product-plan-description">
            <div className="modal-product-plan-description-text-4">
              Includes
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />{" "}
              <span style={{ fontWeight: "500", color: "#A37EE3" }}>
                400 generations
              </span>{" "}
              <MagicWandIconPurple />
              <div style={{ marginLeft: "2px" }}>per month</div>
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HammerIconPricing /> Renovate and redecorate
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <BrushIconPricing /> Empty rooms
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <FurnishPricingIcon /> Furnish
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <RemoveObjectPricingIcon /> Remove objects
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <HDIconPricing /> Increase resolution
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <WallChangePricingIcon /> Change floors
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <PhotographyPricingIcon /> Improve photography
            </div>
            <div className="modal-product-plan-description-text-4">
              <EmptyIcon />
              <VideoPricingIcon /> Generate virtual videos
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unlimited floor plans
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unlimited project sharing
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Add watermarks
            </div>
            <div className="modal-product-plan-description-text-4">
              <CheckIcon />
              Unlimited support
            </div>
            <div className="pricing-modal-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Try Pedra
                <ArrowIcon />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="long-break"> </div>

      <div className="section-one-column-left">
        <div className="title-container">Questions and Answers</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h2 className="question-title">What is home staging?</h2>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Home staging is a real estate marketing technique that involves
                preparing a property (house, apartment, etc.) for sale or rent
                with the aim of making it more attractive to potential buyers or
                tenants. This is achieved through strategic presentation and
                decoration of the space to highlight its most positive features
                and minimize its flaws.
                <br />
                <br />
                The home staging process involves cleaning, depersonalizing, and
                decluttering the property to allow potential buyers or tenants
                to easily visualize how it would be to live in that space. It
                may also include rearranging furniture, adding decorative
                elements, and in some cases, small renovations or repairs to
                improve the overall appearance of the property.
                <br />
                <br />
                The goal of home staging is to create a positive first
                impression on visitors and increase the chances of selling or
                renting the property quickly and at a favorable price. It's an
                increasingly popular strategy in the real estate market due to
                its ability to enhance the visual and emotional appeal of a
                property.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                What does virtual home staging involve?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  Virtual home staging is a variant of traditional home staging
                  that uses digital tools and technology to present a property
                  attractively to potential buyers or tenants. Instead of making
                  physical changes to the property, such as rearranging
                  furniture or adding decorative elements, virtual home staging
                  is primarily carried out in the following ways:
                </div>
                <h4>Image editing:</h4>With Pedra, you can edit images with AI
                to retouch and improve property photographs. This can involve
                removing unwanted objects, adjusting lighting and color, and
                even adding virtual furniture or decoration.
                <br />
                <br />
                <h4>Design rendering:</h4> Create digital representations of how
                the property could look after being decorated or renovated. This
                can help buyers visualize the potential of the space and inspire
                ideas for their own decoration.
                <br />
                <br />
                <h4>Time and money saving:</h4>Virtual home staging is faster
                and more economical than traditional home staging, as it doesn't
                require hiring physical furniture or accessories or making
                physical changes to the property. This can be especially
                beneficial for empty properties or in situations where time is a
                critical factor.
                <br />
                <br />
                <h4>Flexibility and customization:</h4>With virtual home
                staging, it's possible to experiment with different decoration
                styles and space configurations quickly and without commitment.
                This allows adapting the presentation of the property according
                to the target market and the preferences of potential buyers or
                tenants.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">Virtual home staging tips</h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Know your target audience:</h4>Understanding the needs and
                preferences of your target audience will help you create virtual
                home staging content that resonates with them. Consider who the
                potential buyers or tenants are and adapt your approach
                accordingly.
                <br />
                <br />
                <h4>Be honest and transparent:</h4>Ensure that the virtual home
                staging content accurately reflects the appearance and features
                of the property. Avoid over-editing or excessive image
                manipulation, as this can create unrealistic expectations.
                <br />
                <br />
                <h4>Multiple angles:</h4>Capture the property from various
                angles to provide a comprehensive view. This allows viewers to
                have a better idea of the space layout and unique features of
                the property.
                <br />
                <br />
                <h4>Maintain stylistic consistency:</h4>Use a coherent
                decoration style in all images and virtual tours to create a
                uniform visual experience. This helps maintain viewers' interest
                and facilitates visualizing the property as a whole.
                <br />
                <br />
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Virtual home staging prices</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                The price for Pedra is €29 plus VAT per month.
                <br />
                <br />
                With that payment you can generate up to 400 images per month.
                <br />
                <br />
                There is no commitment, so you can cancel when you no longer
                need it.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ5(!FAQ5)}>
              <h3 className="question-title">
                What steps are included in the Virtual Home Staging process?
              </h3>
              {FAQ5 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ5 && (
              <div className="answer-container">
                The home staging process is quite fast and simple. Here are the
                steps to follow to create a virtual home staging with Pedra:
                <br />
                <br />
                1. <strong>Upload project photos:</strong> When creating a
                project, you can upload one or more images to edit.
                <br />
                <br />
                2. <strong>Virtual Home Staging options:</strong> Once you
                select the photos, within the "Edit with AI" dropdown, you'll
                have all the options you need, from emptying rooms to adding
                furniture to your room, renovating spaces, and more.
                <br />
                <br />
                3. <strong>Download your project:</strong> Once you've made all
                the changes and edited the images, you just need to download the
                images of your Home Staging.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ6(!FAQ6)}>
              <h3 className="question-title">
                Can Virtual Home Staging be done on empty properties?
              </h3>
              {FAQ6 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ6 && (
              <div className="answer-container">
                <p>
                  Yes! One of the functions of our virtual home staging tool is
                  to furnish the space. You can use the "Furnish" option to
                  automatically furnish the space with AI, or if you prefer, you
                  can manually add the furniture you want with the "Add object"
                  option.
                </p>
                <p>
                  With either of these options, our Virtual Home Staging tool
                  helps you visualize the potential of your spaces, facilitating
                  decision-making and improving the presentation of the
                  property.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VirtualHomeStaging;
