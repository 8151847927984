// InteriorDesignEs.js
import React, { useState } from "react";
import { ReactComponent as Frame1HomeBuilders } from "./Images/Frame 1 HomeBuilders.svg";
import { ReactComponent as Frame2HomeBuilders } from "./Images/Frame 2 HomeBuilders.svg";
import { ReactComponent as Frame3HomeBuilders } from "./Images/Frame 3 HomeBuilders.svg";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import { Link } from "react-router-dom";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function InteriorDesignEs() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  return (
    <div>
      <div>
        <section className="section-first-wrapper">
          <section className="section-first">
            <div className="section-first-text-container">
              <h1 className="section-first-title">
                Diseño de Interiores Online
              </h1>
              <div className="section-first-text">
                Una herramienta para Diseñadores de Interiores que les ayuda a
                comenzar y vender proyectos de diseño interior.
              </div>
              <div className="section-first-button-container">
                <button className="button-small" onClick={redirectToPage}>
                  Prueba Pedra <ArrowIcon />
                </button>
              </div>
            </div>
          </section>
        </section>

        <div style={{ height: "100px", borderTop: "1px solid #EEEEEE" }}></div>

        <section className="project project-alt">
          <div className="picture">
            <Frame1HomeBuilders />
          </div>

          <div className="description">
            <h2> Planificación rápida de pisos </h2>

            <p className="value-prop-text">
              Olvídate de software difíciles de usar como AutoCAD o Revit.
            </p>
            <p className="value-prop-text">
              Pedra está hecho para hacer la planificación de pisos tan fácil
              como el papel y lápiz: ¡haz todo en segundos!
            </p>
          </div>
        </section>

        <div className="long-break"> </div>

        <section className="project project-alt">
          <div className="picture">
            <Frame2HomeBuilders />
          </div>

          <div className="description">
            <h2> Calcular presupuestos rápidamente </h2>

            <p className="value-prop-text">
              Haz seguimiento del presupuesto de cada cambio: nueva pared, nuevo
              piso, nueva silla, etc.
            </p>
            <p className="value-prop-text">
              Muestra los precios directamente en el lienzo para una
              comunicación más fácil con el cliente.
            </p>
          </div>
        </section>

        <div className="long-break"> </div>

        <section className="project project-alt">
          <div className="picture">
            <Frame3HomeBuilders />
          </div>

          <div className="description">
            <h2> Genera cualquier imagen en segundos </h2>

            <p className="value-prop-text">
              Tu cliente quiere visualizar ideas de diseño de hogar o
              renovación, pero los renders 3D tardan mucho y cuestan mucho
              dinero.
            </p>
            <p className="value-prop-text">
              Con la inteligencia artificial de Pedra, puedes visualizar una
              habitación vacía, una renovación o un cambio de color de pared con
              solo hacer clic en un botón.
            </p>
          </div>
        </section>

        <div className="long-break"> </div>
        <div className="centered-heading">
          <button className="button" onClick={redirectToPage}>
            Prueba Pedra
          </button>
        </div>
        <br></br>
        <br></br>
        <div className="section-one-column-left">
          <div className="title-container">Preguntas y respuestas</div>
        </div>

        <div className="section-one-column-left">
          <div className="faq-container">
            <div className="question-answer-container">
              <div
                className="question-container"
                onClick={() => setFAQ1(!FAQ1)}
              >
                <h2 className="question-title">
                  ¿Qué es el interiorismo con IA?
                </h2>
                {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
              </div>
              {FAQ1 && (
                <div className="answer-container">
                  El interiorismo con inteligencia artificial (IA) es una
                  aplicación innovadora de la tecnología en el diseño de
                  interiores. La IA se utiliza para analizar datos, patrones y
                  preferencias de los usuarios, así como para generar ideas de
                  diseño y recomendaciones personalizadas.
                  <br />
                  <br />
                  En el interiorismo con IA, se pueden emplear diversas técnicas
                  y herramientas, como algoritmos de aprendizaje automático y
                  procesamiento de lenguaje natural, para entender las
                  necesidades y gustos de los clientes. Esto puede incluir desde
                  la selección de colores y muebles hasta la distribución del
                  espacio y la optimización de la iluminación.
                  <br />
                  <br />
                  Además, la IA puede ayudar a los diseñadores a explorar una
                  variedad de opciones de diseño de manera más eficiente y a
                  prever cómo se verían diferentes diseños en un espacio
                  determinado antes de realizar cambios físicos.
                </div>
              )}
            </div>

            <div className="question-answer-container">
              <div
                className="question-container"
                onClick={() => setFAQ2(!FAQ2)}
              >
                <h2 className="question-title">
                  ¿Cómo diseñar interiores online?
                </h2>
                {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
              </div>
              {FAQ2 && (
                <div className="answer-container">
                  <div>
                    Diseñar interiores es un proceso creativo que implica
                    planificar y organizar el espacio interior de una manera que
                    sea funcional, estéticamente atractiva y que satisfaga las
                    necesidades y preferencias del cliente. Aquí hay algunos
                    pasos básicos para diseñar interiores:
                  </div>
                  <h3>Recopilación de información:</h3>Comienza reuniendo toda
                  la información relevante sobre el proyecto, como las
                  necesidades y deseos del cliente, el presupuesto disponible,
                  las limitaciones arquitectónicas y cualquier otro detalle
                  importante.
                  <br />
                  <br />
                  <h3>Análisis del espacio:</h3> Examina el espacio que vas a
                  diseñar, tomando medidas precisas y considerando la
                  distribución de puertas, ventanas, columnas u otros elementos
                  arquitectónicos que puedan afectar el diseño.
                  <br />
                  <br />
                  <h3>Definición de la función:</h3>Determina la función de cada
                  área dentro del espacio y cómo se utilizará. Por ejemplo,
                  identifica zonas de estar, de trabajo, de descanso, etc.
                  <br />
                  <br />
                  <h3>Establecimiento de un concepto:</h3>Desarrolla un concepto
                  de diseño que guíe todo el proyecto. Esto podría ser un tema
                  específico, un estilo de diseño o una paleta de colores.
                  <br />
                  <br />
                  <h3>Diseño preliminar:</h3>Crea con Pedra bocetos preliminares
                  o planos que muestren la disposición general de los muebles,
                  la distribución de los espacios y cualquier característica
                  arquitectónica importante.
                  <br />
                  <br />
                  <h3>Selección de materiales y acabados:</h3> Elige los
                  materiales, texturas y acabados que se utilizarán en el
                  diseño, asegurándote de que complementen el concepto general y
                  cumplan con los requisitos de durabilidad y mantenimiento.
                  <br />
                  <br />
                  <h3>Diseño detallado:</h3>Desarrolla planos detallados que
                  incluyan especificaciones precisas para muebles, accesorios,
                  iluminación, etc.
                  <br />
                  <br />
                  <h3>Presentación al cliente:</h3>Presenta el diseño al cliente
                  utilizando{" "}
                  <Link
                    to="/es/render"
                    style={{ padding: "0px 0px", fontWeight: "bold" }}
                  >
                    renders
                  </Link>
                  ,{" "}
                  <Link
                    to="/es/floorplan"
                    style={{ padding: "0px 0px", fontWeight: "bold" }}
                  >
                    planos de planta
                  </Link>{" "}
                  y muestras de materiales para que puedan visualizar cómo
                  quedará el espacio final.
                  <br />
                  <br />
                </div>
              )}
            </div>

            <div className="question-answer-container">
              <div
                className="question-container"
                onClick={() => setFAQ3(!FAQ3)}
              >
                <h3 className="question-title">
                  Consejos para diseñar interiores con IA
                </h3>
                {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
              </div>
              {FAQ3 && (
                <div className="answer-container">
                  <h4>Generación de ideas:</h4>Experimenta con Pedra y genera
                  ideas asistidas por IA para explorar una amplia variedad de
                  opciones de diseño. La IA puede ayudarte a generar y evaluar
                  rápidamente múltiples conceptos de diseño, lo que te permite
                  encontrar soluciones creativas de manera más eficiente.
                  <br />
                  <br />
                  <h4>Personalización:</h4>Utiliza la IA para personalizar los
                  diseños según las preferencias individuales de cada cliente.
                  Puedes crear recomendaciones de diseño basadas en datos
                  específicos, como colores favoritos, estilos de mobiliario
                  preferidos o patrones de uso del espacio.
                  <br />
                  <br />
                  <h4>Optimización del espacio:</h4>La IA puede ayudarte a
                  optimizar el uso del espacio de manera eficiente. Utiliza
                  Pedra para encontrar la disposición óptima de muebles y
                  accesorios que maximice la funcionalidad y la comodidad dentro
                  del espacio disponible.
                  <br />
                  <br />
                  <h4>Visualización avanzada:</h4>Utiliza la IA para crear
                  renders 3D realistas y visualizaciones del diseño propuesto.
                  Esto ayudará a tus clientes a visualizar cómo se verá el
                  espacio final y a tomar decisiones informadas sobre el diseño.
                  <br />
                  <br />
                </div>
              )}
            </div>

            <div className="question-answer-container">
              <div
                className="question-container"
                onClick={() => setFAQ4(!FAQ4)}
              >
                <h3 className="question-title">
                  Prueba a decorar interiores gratis
                </h3>
                {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
              </div>
              {FAQ4 && (
                <div className="answer-container">
                  Tras el periodo de prueba gratuita el precio es 29€ más IVA al
                  mes.
                  <br />
                  <br />
                  No tiene permanencia, así que puedes darte de baja cuando ya
                  no lo necesites.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InteriorDesignEs;
