import React from "react";
import Article1Frame from "./Images/Article1Frame.png";
import Article4Frame from "./Images/Article4Frame.png";
import Article5Frame from "./Images/Article5Frame.jpeg";
import Article6Frame from "./Images/Article6Frame.jpeg";
import Article7Frame from "./Images/Article7Frame.jpg";
import Article8Frame from "./Images/Article8Frame.png";
import Article9Frame from "./Images/Article9Frame.png";
import { Link } from "react-router-dom";

function BlogFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container" style={{ marginTop: "100px" }}>
          <h2 className="title-container">Blog</h2>
          <div className="title-secondary">
            Bienvenue sur le blog de Pedra <br />
            Dans ce blog, vous apprendrez comment utiliser l'IA pour vous aider
            dans le monde de l'immobilier.
          </div>
        </div>
      </div>
      <br />
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>23 août 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/fr/blog/augmentez-valeur-maison"
              >
                Augmentez la valeur de la maison rapidement et facilement
              </Link>
            </h2>
            <div className="value-prop-text">
              Nous vous montrons comment augmenter la valeur de votre maison
              rapidement et facilement avec plusieurs conseils et astuces pour
              améliorer votre maison avant de la vendre &#127969;
            </div>
          </div>
          <div className="picture">
            <img
              src={Article9Frame}
              alt="Augmentez valeur maison – Illustration décorative"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>21 août 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/fr/blog/espaces-multifonctionnels"
              >
                Espaces multifonctionnels: Qu'est-ce que c'est et comment
                optimiser l'espace?
              </Link>
            </h2>
            <div className="value-prop-text">
              Les espaces multifonctionnels ont pour objectif de maximiser
              l'utilisation de l'espace disponible, en utilisant une même zone
              pour différentes fonctions.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article8Frame}
              alt="Espaces multifonctionnels – Illustration décorative"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>9 août 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/fr/blog/exemples-de-home-staging"
              >
                Exemples de Home Staging : Avant et Après
              </Link>
            </h2>
            <div className="value-prop-text">
              Vous recherchez des exemples de home staging avant et après ? Pour
              vous donner une idée de la puissance de cette technique, voici
              quelques exemples de home staging pour vous.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article7Frame}
              alt="Exemple de Home Staging – Avant et Après – Couverture illustrative"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>19 juillet 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/fr/blog/comment-ajouter-un-ciel-bleu-aux-photographies-immobilieres"
              >
                Comment rendre le ciel bleu dans vos photos immobilières
              </Link>
            </h2>
            <div className="value-prop-text">
              Vous êtes-vous déjà demandé comment rendre le ciel bleu dans
              toutes vos photos immobilières ? Vous découvrirez ici comment
              faire.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article6Frame}
              alt="Comment rendre le ciel bleu dans les photos immobilières - Illustration décorative"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>16 juillet 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-link"
                to="/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere"
              >
                Comment s'assurer que votre photo de couverture d'annonce
                immobilière accélère la vente
              </Link>
            </h2>
            <div className="value-prop-text">
              Choisissez et générez des photos de couverture dans vos annonces
              immobilières qui attirent l'attention et vous aident à vendre
            </div>
          </div>
          <div className="picture">
            <img
              src={Article5Frame}
              alt="Comment choisir la bonne couverture pour une annonce immobilière – Illustration décorative"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>19 juin 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes"
              >
                Comment l'IA peut-elle aider les agents immobiliers à vendre ?
              </Link>
            </h2>
            <div className="value-prop-text">
              Une explication sur ce qu'est l'IA et comment elle peut aider à la
              vente de biens immobiliers
            </div>
          </div>
          <div className="picture">
            <img
              src={Article4Frame}
              alt="Couverture de l'article sur comment l'IA peut aider les agents immobiliers à vendre – Illustration décorative"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>4 avril 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia"
              >
                Comment utiliser l'IA pour vendre des biens immobiliers
              </Link>
            </h2>
            <div className="value-prop-text">
              Dans cet article, nous expliquerons comment utiliser l'IA pour
              vous aider à vendre des biens immobiliers
            </div>
          </div>
          <div className="picture">
            <img
              src={Article1Frame}
              alt="Comment utiliser l'IA pour vendre des biens – Illustration décorative"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogFr;
