import React from "react";
import ReleaseJul2024 from "./Images/ReleaseJul2024.png";
import ReleaseJun2024 from "./Images/ReleaseJun2024.png";
import ReleaseMay2024 from "./Images/ReleaseMay2024.png";
import { Link } from "react-router-dom";

function Releases() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container" style={{ marginTop: "100px" }}>
          <h2 className="title-container">Releases</h2>
          <div className="title-secondary">
            Check Pedra's latest releases here
            <br />
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>JULY 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/add-3d-objects-and-make-sky-blue"
              >
                Pedra: Add 3D objects, make sky blue in 1 click, and enhance or
                enlight images automatically
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/add-3d-objects-and-make-sky-blue"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseJul2024}
                alt="Pedra releases month of July 24 – Decorative banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>JUNE 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/renovate-interiors-with-ai-from-phone"
              >
                Pedra: Renovate interiors from your phone with AI, edit image
                foot notes and edit watermarks
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/renovate-interiors-with-ai-from-phone"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseJun2024}
                alt="Pedra releases month of June 24 – Decorative banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>MAY 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/releases/empty-rooms-and-remove-objects-with-ai"
              >
                Pedra: Clear Rooms and Remove Items, Renovation Styles, and New
                Interface
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/releases/empty-rooms-and-remove-objects-with-ai"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseMay2024}
                alt="Pedra releases month of May 24 – Decorative banner"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Releases;
