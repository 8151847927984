import React from "react";
import Article1Frame from "./Images/Article1Frame.png";
import Article4Frame from "./Images/Article4Frame.png";
import Article5Frame from "./Images/Article5Frame.jpeg";
import Article6Frame from "./Images/Article6Frame.jpeg";
import Article7Frame from "./Images/Article7Frame.jpg";
import Article8Frame from "./Images/Article8Frame.png";
import Article9Frame from "./Images/Article9Frame.png";
import { Link } from "react-router-dom";

function Blog() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container" style={{ marginTop: "100px" }}>
          <h2 className="title-container">Blog</h2>
          <div className="title-secondary">
            Welcome to Pedra's blog <br />
            In this blog you will learn how to use AI to help you in the real
            estate world.
          </div>
        </div>
      </div>

      <br />
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>August 23, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/increase-home-value"
              >
                Increase Home Value Quickly and Easily
              </Link>
            </h2>
            <div className="value-prop-text">
              We show you how to increase the value of your home quickly and
              easily with several tips and advice to improve your home before
              selling it &#127969;
            </div>
          </div>
          <div className="picture">
            <img
              src={Article9Frame}
              alt="Increase home value – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>August 21, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/multifunctional-spaces"
              >
                Multifunctional Spaces: What Are They and How to Optimize Space?
              </Link>
            </h2>
            <div className="value-prop-text">
              Multifunctional spaces aim to maximize the use of available space
              by using the same area for different functions.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article8Frame}
              alt="Multifunctional spaces – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>9 August, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/home-staging-examples"
              >
                Home Staging Examples: Before and After
              </Link>
            </h2>
            <div className="value-prop-text">
              Are you looking for home staging before and after examples? To
              give you an idea of the power of this technique, here are some
              home staging examples for you.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article7Frame}
              alt="Home Staging Example – Before and After – Illustrative Cover"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>19 July, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/how-to-make-sky-blue-real-estate-photo"
              >
                How to Make the Sky Blue in Your Property Photos
              </Link>
            </h2>
            <div className="value-prop-text">
              Have you ever wondered how to make the sky blue in all your
              property photos? Here you'll find out how.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article6Frame}
              alt="How to turn sky blue in real estate photos - Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>16 July, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo"
              >
                How to Ensure Your Real Estate Listing Cover Photo Speeds Up the
                Sale
              </Link>
            </h2>
            <div className="value-prop-text">
              Choose and generate cover photos in your real estate listings that
              catch attention and help you sell
            </div>
          </div>
          <div className="picture">
            <img
              src={Article5Frame}
              alt="How to pick the right real estate listing cover – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>19 June, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/sell-more-properties-with-ai"
              >
                How Can AI Help Real Estate Agents Sell?
              </Link>
            </h2>
            <div className="value-prop-text">
              An explanation about what is AI and how it can help on the sale of
              real estate properties
            </div>
          </div>
          <div className="picture">
            <img
              src={Article4Frame}
              alt="Cover of the article about how can AI help real estate agents sell – Decorative illustration"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>4 April, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/blog/how-to-sell-properties-with-ai"
              >
                How to use AI to sell real estate properties
              </Link>
            </h2>
            <div className="value-prop-text">
              In this article we will explain how to ue AI to help you sell real
              estate properties
            </div>
          </div>
          <div className="picture">
            <img
              src={Article1Frame}
              alt="How to use AI to sell properties – Decorative illustration"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
