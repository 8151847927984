import React, { useState } from "react";

//Icons
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";

//Images
import RealEstatePhotographyEsPage1 from "./Images/RealEstatePhotographyEsPage1En.png";
import RealEstatePhotographyEsPage2 from "./Images/RealEstatePhotographyEsPage2En.png";
import RealEstatePhotographyEsPage3 from "./Images/RealEstatePhotographyEsPage3En.png";
import RealEstatePhotographyEsPage4 from "./Images/RealEstatePhotographyEsPage4En.png";
import RealEstatePhotographyEsPage5 from "./Images/RealEstatePhotographyEsPage5En.jpeg";
import RealEstatePhotographyEsPage6 from "./Images/RealEstatePhotographyEsPage6En.jpeg";
import CustomerLogos from "./CustomerLogos";
import iadLogo from "./Images/iadLogo.png";
import OrangeFlowerIcon from "./Icons/OrangeFlowerIcon.jsx";
import OrangeSunIcon from "./Icons/OrangeSunIcon.jsx";
import OrangeHDIcon from "./Icons/OrangeHDIcon.jsx";
import OrangeCleanerIcon from "./Icons/OrangeCleanerIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import OrangeBlueSky from "./Icons/OrangeBlueSky.jsx";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

function redirectToPage() {
  if (window.location.href.includes("/fr")) {
    window.open("https://app.pedra.so/fr", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function RealEstatePhotographyFr() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);
  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">
              Améliorez vos photographies immobilières avec l'IA en 1 clic
            </h1>
            <div className="section-first-text">
              <div
                className="section-first-text-bold"
                style={{ width: "100%" }}
              >
                Utilisez l'IA de Pedra pour améliorer facilement vos
                photographies immobilières
              </div>
              <div className="section-first-text-gray">
                Corrigez les perspectives, améliorez la luminosité et supprimez
                l'inutile
              </div>
            </div>
            <div className="section-first-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Essayez Pedra <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Director of Photography at aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO at Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO at Gilart Consultors"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  5 000+ professionnels de l'immobilier utilisent déjà Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <div className="header-video-frame">
              <div className="header-video-frame-circles-container">
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
              </div>
              <video
                className="header-picture"
                width="100%"
                height="auto"
                playsInline
                autoPlay
                muted
                loop
                alt="Logiciel pour rendus immobiliers, Home Staging virtuel et plans d'étage"
                style={{
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                <source
                  src="https://pedraimages.s3.eu-west-3.amazonaws.com/Pedra+demo+video+09+Jul+24+English.mp4"
                  type="video/mp4"
                />
                Votre navigateur ne prend pas en charge la balise vidéo.
              </video>
            </div>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          PLUS DE 5 000 PROFESSIONNELS DE L'IMMOBILIER FONT DÉJÀ CONFIANCE À
          PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">
          Améliorez votre photographie immobilière en 1 clic
        </h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeFlowerIcon />
                <h2 className="feature-title">
                  Améliorez facilement vos photos de propriétés
                </h2>
                <div className="feature-text">
                  Téléchargez vos images et cliquez sur "Embellir et corriger".
                  Pedra s'occupera d'améliorer la luminosité et la couleur, et
                  de corriger les perspectives.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage1}
                alt="Correction de la perspective, de la couleur et de la lumière avec l'IA"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h2 className="feature-title">
                  Supprimez les objets inutiles de vos photos de propriétés
                </h2>
                <div className="feature-text">
                  L'IA de Pedra supprimera les objets des pièces, tels que les
                  photos de famille, les drapeaux, les tableaux, et plus encore.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage2}
                alt="Suppression d'objet de la photographie immobilière"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeSunIcon />
                <h2 className="feature-title">
                  Éclaircissez automatiquement les pièces très sombres
                </h2>
                <div className="feature-text">
                  Utilisez l'IA de Pedra pour corriger l'éclairage dans les
                  pièces trop sombres.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage3}
                alt="Éclaircir les pièces très sombres"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeHDIcon />
                <h2 className="feature-title">
                  Augmentez la résolution de vos photos de maison en HD
                </h2>
                <div className="feature-text">
                  Ne vous inquiétez pas des photos en basse résolution. Avec
                  Pedra, vous pouvez augmenter la résolution de vos images en HD
                  en un seul clic.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage4}
                alt="Outil d'amélioration de la résolution HD pour les murs et les sols grâce aux rendus IA"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeCleanerIcon />
                <h2 className="feature-title">
                  Changez les matériaux des murs et des sols des propriétés avec
                  l'IA
                </h2>
                <div className="feature-text">
                  Utilisez l'IA pour changer le matériau des sols et des murs.
                  Si vous avez une propriété avec de l'herbe mal entretenue,
                  avec Pedra, vous pouvez la montrer sous son meilleur jour.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage5}
                alt="Outil pour changer les murs et les sols via des rendus IA"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeBlueSky />
                <h2 className="feature-title">Mettez le ciel bleu avec l'IA</h2>
                <div className="feature-text">
                  Utilisez l'IA de Pedra pour corriger une mauvaise journée. Ne
                  laissez pas la météo gâcher la séance photo de votre
                  propriété.
                </div>
              </div>
              <img
                src={RealEstatePhotographyEsPage6}
                alt="Mettre le ciel bleu avec l'IA"
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h3 className="quote">"C'est la découverte du siècle"</h3>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "45px",
                  height: "auto",
                }}
                className="quote-author-image"
                src={iadLogo}
                alt="Logo iad pour Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Bouchra</div>
                <div className="quote-author-company">iad Espagne</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br></br>
      <br></br>
      <div className="section-first-button-container">
        <button className="button-top-of-page" onClick={redirectToPage}>
          Essayez Pedra <ArrowIcon />
        </button>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">Qu'est-ce que Pedra ?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Pedra est une application d'IA conçue pour les agents et les
                entreprises immobilières.
                <br />
                <br />
                L'objectif de Pedra est de vous aider à vendre plus en réduisant
                les coûts.
                <br />
                <br />
                En un seul clic, vous pouvez améliorer les photographies de vos
                propriétés, en optimisant la lumière, la couleur et en
                corrigeant les perspectives ou la définition. Cela vous fait
                gagner des heures d'édition dans Photoshop, Lightroom ou avec
                des photographes professionnels.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                Quelles fonctionnalités comprend le kit d'amélioration photo de
                Pedra ?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <div>
                  Le kit d'amélioration de photographie immobilière de Pedra
                  comprend les fonctionnalités suivantes :
                </div>
                <h4>Correction automatique de la perspective :</h4>Avec Pedra,
                vous pouvez redresser et corriger les perspectives de vos
                photographies automatiquement en un seul clic. Cela vous fait
                gagner du temps et des efforts. La fonctionnalité prend
                généralement 5 secondes.
                <br />
                <br />
                <h4>Embellissement automatique des photos :</h4>Améliorez la
                lumière et la couleur de la couleur de vos photographies
                immobilières d'un seul clic. Cliquez et Pedra améliorera les
                couleurs et les lumières, rendant la photographie plus
                attrayante. La fonctionnalité prend également 5 secondes.
                <br />
                <br />
                <h4>Correction et embellissement des photographies :</h4>Vous
                pouvez également améliorer la lumière, la couleur et la
                perspective de vos photos simultanément. Cette fonctionnalité
                prend aussi 5 secondes.
                <br />
                <br />
                <h4>Éclairage automatique des photographies :</h4>Si vous avez
                des photos très sombres, avec Pedra vous pouvez les illuminer
                pour qu'elles apparaissent clairement. Ainsi, vos propriétés
                seront présentées de manière plus attrayante, améliorant la
                relation avec le propriétaire et augmentant les chances de
                vente.
                <br />
                <br />
                <h4>Augmentation de la résolution des photographies en HD :</h4>
                Vous pouvez également augmenter la résolution de vos
                photographies en HD en un seul clic. Il suffit de sélectionner
                l'option "Convertir en HD". Des photographies de meilleure
                qualité améliorent la présence de votre annonce sur les
                principaux portails immobiliers.
                <br />
                <br />
                <h4>Suppression d'objets :</h4>Avec Pedra, vous pouvez également
                supprimer tout type d'objet des photographies immobilières, tels
                que des photos de famille, des déchets, des tableaux, des croix,
                des drapeaux, etc. Il suffit d'utiliser le pinceau Pedra sur les
                objets que vous souhaitez supprimer et de sélectionner "Générer
                l'image".
                <br />
                <br />
                <h4>Suppression de filigrane :</h4>Vous pouvez supprimer les
                filigranes d'autres agences immobilières en utilisant la même
                fonctionnalité "Supprimer l'objet".
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                Pourquoi devrais-je utiliser Pedra pour améliorer mes
                photographies de propriétés ?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Améliorer vos photographies de propriétés avant de les publier
                peut avoir un impact significatif sur la perception des
                acheteurs ou locataires potentiels de vos propriétés. Voici
                quelques raisons clés :
                <br />
                <h4>Première impression :</h4> La photographie est souvent le
                premier contact qu'une partie intéressée a avec votre propriété.
                Des images de haute qualité créent une première impression
                positive, qui peut influencer significativement la décision
                d'explorer davantage la propriété.
                <br />
                <h4>Mise en valeur des caractéristiques :</h4> Une bonne
                retouche photographique peut mettre en valeur les meilleures
                caractéristiques de votre propriété, telles que l'espace des
                pièces, l'éclairage naturel et les finitions de qualité. Cela
                peut aider les acheteurs potentiels à visualiser la valeur de la
                propriété.
                <br />
                <h4>Différenciation sur le marché :</h4> Le marché immobilier
                peut être très compétitif. Des photographies bien produites
                peuvent faire ressortir votre propriété parmi d'autres sur le
                marché, attirant plus d'intérêt et possiblement accélérant la
                vente ou la location. Des images attrayantes et de qualité ont
                également tendance à générer plus d'interactions sur les
                plateformes d'annonces en ligne et les réseaux sociaux. Cela
                peut augmenter la visibilité de votre annonce et attirer plus de
                parties intéressées.
                <br />
                <h4>Réduction du temps sur le marché :</h4> Les propriétés avec
                des images attrayantes ont tendance à se vendre ou à se louer
                plus rapidement. Les acheteurs sont plus enclins à prendre des
                décisions rapides lorsque les images leur fournissent une bonne
                représentation de la propriété.
                <br />
                <h4>Promotion efficace :</h4> Des images de haute qualité sont
                essentielles pour la promotion efficace de toute propriété. Non
                seulement elles sont utiles pour les annonces en ligne, mais
                aussi pour les brochures, les présentations et autres supports
                marketing. En résumé, investir du temps et des ressources dans
                l'amélioration de vos photographies de propriétés peut entraîner
                une meilleure perception de vos propriétés, une différenciation
                sur le marché et une vente ou location plus rapide.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Combien coûte Pedra ?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra coûte 29 € par mois et est un abonnement mensuel sans
                engagement. Si vous décidez de ne plus l'utiliser, vous pouvez
                vous désabonner à tout moment.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RealEstatePhotographyFr;
