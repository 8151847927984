// RenderEs.js

import React, { useState } from "react";

//Icons
import RedEyeIcon from "./Icons/RedEyeIcon";
import RedHammerIcon from "./Icons/RedHammerIcon";
import RedMultipleIcon from "./Icons/RedMultipleIcon";
import RedBrushIcon from "./Icons/RedBrushIcon";
import RedDownloadIcon from "./Icons/RedDownloadIcon";
import RedPlantIcon from "./Icons/RedPlantIcon";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";

//Images
import RenderHeader from "./Images/RenderHeader.webp";
import RenderFeature1 from "./Images/RenderFeature1.png";
import RenderFeature2 from "./Images/RenderFeature2.png";
import RenderFeature3 from "./Images/RenderFeature3.png";
import RenderFeature4 from "./Images/RenderFeature4.png";
import RenderFeature5 from "./Images/RenderFeature5.png";
import RenderFeature6 from "./Images/RenderFeature6.png";
import RenderStep1 from "./Images/RenderStep1.png";
import RenderStep2 from "./Images/RenderStep2.png";
import CustomerLogos from "./CustomerLogos.js";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function RenderEs() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);

  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">Renders con IA</h1>
            <div className="section-first-text">
              Usa Pedra para generar renders profesionales de renovación con
              inteligencia artificial. Solo carga tu imagen y selecciona una
              opción de renovación.
            </div>
            <div className="section-first-button-container">
              <button
                className="button-small"
                onClick={redirectToPage}
                style={{
                  backgroundColor: "#EB5757",
                  border: "1px solid #EB5757",
                }}
              >
                Prueba Pedra <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Director of Photography at aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO at Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO at Gilart Consultors"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  5,000+ profesionales ya usan Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <img
              src={RenderHeader}
              alt="Renders IA"
              width="1200"
              height="800"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MÁS DE 5,000 PROFESIONALES INMOBILIARIOS YA CONFÍAN EN PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">Renders inmobiliarios en 1 click</h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedHammerIcon />
                <h2 className="feature-title">
                  Crea renders con IA desde una imagen
                </h2>
                <div className="feature-text">
                  Genera un render profesional a partir de una sola imagen
                </div>
              </div>
              <img
                src={RenderFeature1}
                alt="Crea Renders con ia"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedMultipleIcon />
                <h2 className="feature-title">
                  Genera múltiples renders a la vez
                </h2>
                <div className="feature-text">
                  También puedes generar múltiples renders a la vez
                  seleccionándolos y eligiendo una opción de renovación
                </div>
              </div>
              <img
                src={RenderFeature2}
                alt="Renders con inteligencia artificial"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedEyeIcon />
                <h2 className="feature-title">
                  Selecciona estilos de renderizado
                </h2>
                <div className="feature-text">
                  Elige uno de nuestros diversos estilos de renderizado:
                  minimalista, escandinavo, bohemio, etc.
                </div>
              </div>
              <img
                src={RenderFeature3}
                alt="Seleccionar Estilos de Renderizado - Captura de pantalla del software"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedDownloadIcon />
                <h2 className="feature-title">
                  Descarga el render en cualquier formato
                </h2>
                <div className="feature-text">
                  Descarga renders en formatos PNG, JPG o JPEG
                </div>
              </div>
              <img
                src={RenderFeature4}
                alt="Descargar Render en Cualquier Formato - Captura de pantalla del software"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedPlantIcon />
                <h2 className="feature-title">
                  Agrega cualquier objeto con IA
                </h2>
                <div className="feature-text">
                  Agrega objetos específicos a las habitaciones: plantas, mesas,
                  etc.
                </div>
              </div>
              <img
                src={RenderFeature5}
                alt="Agregar Cualquier Objeto con IA"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedBrushIcon />
                <h2 className="feature-title">Elimina objetos con la IA</h2>
                <div className="feature-text">
                  Elimina partes de una imagen antes o después del renderizado
                </div>
              </div>
              <img
                src={RenderFeature6}
                alt="Eliminar Objetos con IA"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">
          ¿Cómo hacer un render de renovación con IA?
        </h2>
      </div>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={RenderStep1}
            alt="Cómo Subir Un Archivo – Diagram"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>

        <div className="description">
          <h5>PASO 1</h5>
          <h2>Crea un nuevo proyecto y sube 1 o más imágenes</h2>
        </div>
      </section>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={RenderStep2}
            alt="Cómo Crear Un Render – Diagram"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>

        <div className="description">
          <h5>PASO 2</h5>
          <h2>Selecciona una de las opciones de renovación con IA</h2>
          <div className="value-prop-text">
            La renovación tardará 15 segundos. Puedes generar varias
            renovaciones a la misma vez.
          </div>
        </div>
      </section>

      <div className="long-break"> </div>
      <div className="centered-heading">
        <button
          className="button"
          onClick={redirectToPage}
          style={{ backgroundColor: "#EB5757", border: "1px solid #EB5757" }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#E13A3A"; // Darker background color on hover
            e.target.style.borderColor = "#E13A3A"; // Darker border color on hover
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "#EB5757"; // Restore original background color on mouse out
            e.target.style.borderColor = "#EB5757"; // Restore original border color on mouse out
          }}
        >
          Prueba Pedra <ArrowIcon />
        </button>
      </div>
      <br></br>
      <br></br>
      <div className="section-one-column-left">
        <div className="title-container">Preguntas y respuestas</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">¿Qué es un render IA?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Un render IA, en el contexto del diseño y la visualización
                arquitectónica, se refiere a la aplicación de la inteligencia
                artificial (IA) para generar imágenes renderizadas de alta
                calidad de espacios arquitectónicos. La inteligencia artificial
                se utiliza para mejorar y optimizar el proceso de renderizado,
                permitiendo resultados más realistas y eficientes.
                <br />
                <br />
                La IA en el renderizado permite conseguir resultados de manera
                más rápida que con el método tradicional. Los métodos antiguos
                involucran horas o semanas de trabajo ya que hay que re-crear
                modelos 3D de las habitaciones y procesar los sistemas de
                renderizado. Con la IA esto ha cambiado. Los renders pueden
                generarse a partir de imágenes y crear resultados espectaculares
                a partir de la imagen original con nuevos estilos aplicados.
                <br />
                <br />
                La inteligencia artificial en el renderizado de imágenes de
                propiedades inmobiliarias no solo ahorra tiempo si no que da
                flexibilidad a la hora de imaginar espacios para los
                inmobiliarios, los propietarios y los compradores. La tecnología
                ayuda a visualizar el potencial de los espacios de una manera
                nunca vista.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                ¿Cuáles son las ventajas de utilizar renders con IA?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <h4>Calidad mejorada:</h4>Los renders con IA pueden producir
                resultados visualmente impresionantes con un alto grado de
                realismo. Los algoritmos de IA pueden simular efectos de
                iluminación, sombras y texturas de manera más precisa que los
                métodos tradicionales.
                <br />
                <br />
                <h4>Eficiencia en el tiempo de renderización:</h4>La
                inteligencia artificial puede acelerar el proceso de
                renderización al optimizar los cálculos necesarios para generar
                una imagen. Esto puede reducir significativamente los tiempos de
                renderización, lo que es especialmente beneficioso en proyectos
                con plazos ajustados.
                <br />
                <br />
                <h4>Automatización de tareas repetitivas:</h4>La IA puede
                automatizar ciertas tareas dentro del proceso de renderización.
                Esto libera tiempo para los artistas y diseñadores para
                centrarse en aspectos creativos y conceptuales.
                <br />
                <br />
                <h4>Personalización y adaptabilidad:</h4>Los algoritmos de IA
                pueden aprender de patrones y preferencias específicas, lo que
                permite la personalización de los renders según las necesidades
                del proyecto o las preferencias del cliente. Esto puede conducir
                a resultados más adaptados y satisfactorios.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                ¿Cómo funciona un render con IA?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Captura de datos y entrenamiento del modelo:</h4>
                <li>
                  El proceso comienza con la recopilación de datos de entrada,
                  que pueden incluir imágenes de referencia, modelos 3D,
                  texturas y otros elementos relacionados con la escena a
                  renderizar.
                </li>
                <li>
                  Estos datos se utilizan para entrenar un modelo de IA,
                  generalmente una red neuronal, que aprenderá a mapear las
                  características de la entrada a la salida deseada, es decir,
                  la imagen renderizada.
                </li>
                <br />
                <br />
                <h4>Procesamiento de la escena:</h4>
                <li>
                  Una vez que el modelo está entrenado, se utiliza para procesar
                  la escena a renderizar. Esto implica la generación de píxeles
                  para cada punto de la imagen final.
                </li>
                <li>
                  Durante este proceso, el modelo de IA puede tener en cuenta
                  una variedad de factores, como la iluminación, los materiales,
                  las sombras, las texturas y la geometría de la escena
                </li>
                <br />
                <br />
                <h4>Generación de la imagen renderizada:</h4>
                <li>
                  El modelo de IA genera la imagen renderizada pixel por pixel,
                  utilizando la información aprendida durante el entrenamiento
                  para tomar decisiones sobre cómo renderizar cada parte de la
                  escena.
                </li>
                <li>
                  Esto puede incluir la aplicación de efectos de iluminación
                  global, sombreado, reflexiones, refracciones y otros efectos
                  visuales para mejorar la calidad y realismo de la imagen
                  final.
                </li>
                <br />
                <br />
                <h4>Refinamiento y postprocesamiento:</h4>
                <li>
                  Una vez que se ha generado la imagen inicial, puede pasar por
                  un proceso de refinamiento y postprocesamiento para mejorar
                  aún más su calidad.
                </li>
                <li>
                  Esto puede incluir la corrección de artefactos visuales, la
                  aplicación de efectos de posprocesamiento como el desenfoque o
                  el brillo, y otras técnicas para mejorar la estética general
                  de la imagen.
                </li>
                <br />
                <br />
                <h4>Iteración y ajuste:</h4>
                <li>
                  Dependiendo de los resultados obtenidos, el proceso de
                  renderización con IA puede implicar iteraciones adicionales,
                  donde se ajustan los parámetros del modelo y se refinan los
                  resultados hasta alcanzar el nivel deseado de calidad y
                  realismo
                </li>
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">Prueba gratis renders con IA</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Tras el periodo de prueba gratuita el precio es 29€ más IVA al
                mes.
                <br />
                <br />
                No tiene permanencia, así que puedes darte de baja cuando ya no
                lo necesites.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RenderEs;
