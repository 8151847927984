// LangManager.js
// This code:
//   -> Changes the lang attribute from index.html depending on the url of the page
//   -> Changes the canonical URL setting from index.html depending on the url of the page
//   -> Changes the meta description from index.html depending on the url of the page
//   -> Changes the meta title from index.html depending on the url of the page
// This helps with SEO, since pages can then know if the language is properly set for the page

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LangManager = () => {
  const location = useLocation();

  useEffect(() => {
    // Check if the URL includes 'es'
    const newLang = location.pathname.includes("/es")
      ? "es"
      : location.pathname.includes("/fr")
      ? "fr"
      : "en";

    // Update the lang attribute of the HTML tag
    document.documentElement.lang = newLang;

    // Update the canonical setting based on the URL
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      const currentUrl = window.location.href;
      canonicalLink.href = currentUrl;
    }

    // Update the meta description based on the URL
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      const currentUrl = window.location.href;
      let newDescription = "";

      if (currentUrl.includes("floorplan")) {
        newDescription =
          newLang === "es"
            ? "Dibuja planos de planta con la herramienta online para crear planos de Pedra, además te explicamos cómo crearlos de forma sencilla paso a paso."
            : newLang === "fr"
            ? "Dessinez des plans d'étage avec l'outil en ligne pour créer des plans avec Pedra, en plus nous vous expliquons comment les créer facilement étape par étape."
            : "Create a floor plan in seconds. You can design floor plans with different layer types: doors, windows, furniture and edit their parameters.";
      } else if (currentUrl.includes("add-3d-objects-and-make-sky-blue")) {
        newDescription =
          "Add 3D objects and make sky blue to create realistic virtual home stagings easily in seconds.";
      } else if (currentUrl.includes("help/how-to-remove-object")) {
        newDescription =
          "Learn how to eliminate unwanted objects and furniture from your real estate photos with Pedra. Step-by-step tutorial to enhance property presentation.";
      } else if (currentUrl.includes("es/help/como-borrar-objeto")) {
        newDescription =
          "Aprende a eliminar objetos y muebles no deseados de tus fotos inmobiliarias con Pedra. Tutorial paso a paso para mejorar la presentación de propiedades.";
      } else if (
        [
          "/fr/blog/augmentez-valeur-maison",
          "/blog/increase-home-value",
          "/es/blog/aumenta-valor-vivienda",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Nous vous montrons comment augmenter la valeur de votre maison rapidement et facilement avec plusieurs conseils et astuces pour améliorer votre maison avant de la vendre &#127969;"
          : newLang === "es"
          ? "Te enseñamos cómo aumentar el valor de la vivienda de forma rápida y sencilla con varios consejos y tips para mejorar tu hogar antes de venderlo &#127969;"
          : "We show you how to increase the value of your home quickly and easily with several tips and advice to improve your home before selling it &#127969;";
      } else if (
        [
          "/fr/blog/espaces-multifonctionnels",
          "/blog/multifunctional-spaces",
          "/es/blog/espacios-multifuncionales",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Les espaces multifonctionnels ont pour objectif de maximiser l'utilisation de l'espace disponible, en utilisant une même zone pour différentes fonctions."
          : newLang === "es"
          ? "Los espacios multifuncionales tienen el objetivo de aprovechar al máximo el espacio posible, usando una misma zona para diferentes funciones."
          : "Multifunctional spaces aim to maximize the use of available space by using the same area for different functions.";
      } else if (currentUrl.includes("anade-objetos-3d-y-cielo-azul")) {
        newDescription =
          "Añade objetos 3D y pon el cielo azul para crear home staging virtuales realista en segundos de manera fácil.";
      } else if (currentUrl.includes("help/how-to-renovate-houses-virtually")) {
        newDescription =
          "How to virtually renovate a room. Create a virtual renovation of a house in seconds.";
      } else if (currentUrl.includes("help/como-renovar-casas-virtualmente")) {
        newDescription =
          "Cómo renovar virtualmente una habitación. Crea renovaciones virtuales de una casa en segundos.";
      } else if (currentUrl.includes("blog/sell-more-properties-with-ai")) {
        newDescription =
          "An explanation about what is AI and how it can help on the sale of real estate properties";
      } else if (
        currentUrl.includes("/fr/help/comment-ajouter-des-objets-3d")
      ) {
        newDescription =
          "Apprenez à utiliser l'outil 'Add Object' pour créer des mises en scène virtuelles avec Pedra. Ajoutez, positionnez et redimensionnez des objets 3D facilement.";
      } else if (
        currentUrl.includes("/fr/help/comment-renover-des-maisons-en-virtuel")
      ) {
        newDescription =
          "Comment rénover virtuellement une pièce. Créez des rénovations virtuelles d'une maison en quelques secondes";
      } else if (currentUrl.includes("/fr/help/comment-meubler-en-virtuel")) {
        newDescription =
          "Apprenez à meubler virtuellement des pièces et des propriétés. Ce tutoriel vous aidera à meubler comme un pro.";
      } else if (currentUrl.includes("/fr/help/comment-supprimer-un-objet")) {
        newDescription =
          "Apprenez à éliminer les objets et meubles indésirables de vos photos immobilières avec Pedra. Tutoriel étape par étape pour améliorer la présentation de vos propriétés.";
      } else if (currentUrl.includes("interiordesign")) {
        newDescription =
          newLang === "es"
            ? "Diseña interiores con Pedra, aplica la IA al interiorismo y crea diseños de interiores online de forma rápida y sencilla. Pruébalo de forma gratuita"
            : newLang === "fr"
            ? "Découvrez Pedra, l'outil innovant pour les designers d'intérieur. Planifiez, budgétisez et visualisez vos projets rapidement. Essayez Pedra aujourd'hui !"
            : "Use Pedra for interior design to create renders, virtual home stagings, floor plans, image enhancement, and more in seconds. Pedra helps you sell interior design projects.";
      } else if (currentUrl.includes("real-estate-photography")) {
        newDescription =
          newLang === "es"
            ? "Mejora tus fotografías inmobiliarias con IA: arregla perspectivas, mejora color, luz y definición en segundos de manera automática. Quita objetos innecesarios."
            : newLang === "fr"
            ? "Utilisez l'IA pour perfectionner facilement vos photos immobilières. Que vous souhaitiez ajuster la lumière, corriger les perspectives ou enlever les objets inutiles, Pedra offre une solution rapide et efficace pour mettre en valeur vos propriétés"
            : "Improve your real estate photography with AI: fix perspectives, improve color, light and resolution in seconds automatically. Remove unwanted objects.";
      } else if (currentUrl.includes("realestate")) {
        newDescription =
          newLang === "es"
            ? "Usa Pedra para inmobiliarias para crear renders, home staging virtuales, planos de planta, mejora de imagen y más en segundos. Pedra te ayuda a vender inmuembles."
            : newLang === "fr"
            ? "Découvrez comment Pedra peut transformer vos photos immobilières. Avec des outils puissants pour la rénovation, le home staging, et la suppression d'objets, améliorez vos annonces facilement. Essayez Pedra dès maintenant pour attirer plus d'acheteurs et vendre plus rapidement."
            : "Use Pedra for real estate to create renders, virtual home stagings, floor plans, image enhancement, and more in seconds. Pedra helps you sell properties.";
      } else if (currentUrl.includes("como-vender-casa")) {
        newDescription =
          newLang === "es"
            ? "Consejos y recomendaciones de cómo vender tu casa rápido, desde qué debes tener en cuenta antes de vender tu casa hasta cómo prepararla para venderla rápido."
            : "Use Pedra for real estate to create renders, virtual home stagings, floor plans, image enhancement, and more in seconds. Pedra helps you sell properties.";
      } else if (currentUrl.includes("renovar-casa")) {
        newDescription =
          newLang === "es"
            ? "Renueva tu casa rápido, sin obras y con 6 ideas de cómo remodelar tu casa y qué necesitas para ello. Además de las herramientas que puedes usar para renovarla."
            : "Use Pedra for real estate to create renders, virtual home stagings, floor plans, image enhancement, and more in seconds. Pedra helps you sell properties.";
      } else if (currentUrl.includes("pricing")) {
        newDescription =
          newLang === "es"
            ? "Usa Pedra para generar renders inmobiliarios gratis. Usa el Plan Pro por 29€ al mes. Cancela en cualquier momento."
            : newLang === "fr"
            ? "Découvrez notre forfait Pro à 29€/mois avec 400 générations d'images, rénovation, home staging et plus. Annulez à tout moment. Essayez Pedra maintenant!"
            : "Try Pedra to generate free renders. Use the Pro Plan for €29 per month. Cancel anytime.";
      } else if (currentUrl.includes("render")) {
        newDescription =
          newLang === "es"
            ? "Crea renders con IA y prueba el software gratis para generar de forma rápida renders 3d con ayuda de la inteligencia artificial."
            : newLang === "fr"
            ? "Découvrez comment utiliser l'IA pour créer des rendus immobiliers époustouflants. Téléchargez vos images et choisissez parmi divers styles de rénovation."
            : "Create AI renders and try the software for free to quickly generate 3D renders with the help of artificial intelligence.";
      } else if (
        currentUrl.includes("como-utilizar-ia-para-vender-propiedades") ||
        currentUrl.includes("how-to-sell-properties-with-ai")
      ) {
        newDescription =
          newLang === "es"
            ? "La IA en inmobiliarias usa la tecnologías para mejorar y optimizar diversos aspectos automatizando procesos del sector inmobiliario. ¡Te mostramos cómo usarlo!"
            : "AI in real estate uses technology to enhance and optimize various aspects by automating processes within the real estate sector.";
      } else if (
        currentUrl.includes("vende-mas-propiedades-inmobiliarias-con-ia")
      ) {
        newDescription =
          newLang === "es"
            ? "Una explicación sobre qué es la IA y cómo puede ayudar a acelerar a la venta de inmuebles por menos coste."
            : "An explanation of what AI is and how it can accelerate real estate sales.";
      } else if (currentUrl.includes("home-staging-virtual")) {
        newDescription =
          newLang === "es"
            ? "El home staging acelera tus ventas inmobiliarias. Te proporcionamos una herramienta para realizar un home staging virtual. Home staging fácil y rápido."
            : newLang === "fr"
            ? "Le home staging accélère vos ventes immobilières. Nous vous fournissons un outil pour réaliser un home staging virtuel. Home staging facile et rapide."
            : "Home staging speeds up your real estate sales. We provide you with a tool to create a virtual home staging. Easy and fast home staging.";
      } else if (currentUrl.includes("es/mls")) {
        newDescription =
          "Proveedor oficial de IA de la MLS: propuestas de renovación con IA, home staging virtual, vaciado de habitaciones y más.";
      } else if (currentUrl.includes("privacy")) {
        newDescription =
          newLang === "es"
            ? "Politica de Privacidad de Pedra, aplicación con IA para crear propuestas de renovación, home staging virtual, y otras infografías."
            : newLang === "fr"
            ? "Découvrez la politique de confidentialité de Pedra.so. Informez-vous sur la collecte, l'utilisation, et la protection de vos données personnelles lors de l'utilisation de notre application."
            : "Pedra's privacy policy, an AI application to create renovation proposals, virtual home stagings, and other render types";
      } else if (currentUrl.includes("legal")) {
        newDescription =
          newLang === "es"
            ? "Aviso legal de Pedra, aplicación con IA para crear propuestas de renovación, home staging virtual, y otras infografías."
            : newLang === "fr"
            ? "Consultez les mentions légales de Pedra.so. Découvrez les informations sur le propriétaire, les conditions d'utilisation, et la protection des données personnelles."
            : "Pedra's legal notice, an AI application to create renovation proposals, virtual home stagings, and other render types";
      } else if (currentUrl.includes("about")) {
        newDescription =
          newLang === "es"
            ? "Soy Felix Ingla, I empecé Pedra, una herramienta online para transformar tus propiedades de inmobiliarias de manera fácil con inteligencia artificial."
            : newLang === "fr"
            ? "Découvrez l'histoire de Pedra et de son fondateur, Felix Ingla. Apprenez comment Pedra est né pour répondre aux besoins du marché immobilier en offrant une solution simple et efficace pour améliorer la présentation des biens. Explorez notre engagement envers la satisfaction client et comment notre outil peut vous aider à optimiser vos photos immobilières et plus encore."
            : "I'm Felix Ingla, and I started Pedra, an online tool to transform your real estate properties easily with artificial intelligence.";
      } else if (
        currentUrl.includes("/blog/how-to-make-sky-blue-real-estate-photo")
      ) {
        newDescription =
          "How to make the sky in your real estate photos blue easily";
      } else if (
        currentUrl.includes(
          "/es/blog/como-poner-cielo-azul-fotografias-de-inmuebles"
        )
      ) {
        newDescription =
          "Como poner el cielo azul y despejado en las fotografías de tus inmuebles";
      } else if (
        currentUrl.includes(
          "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia"
        )
      ) {
        newDescription =
          "Découvrez comment l'IA révolutionne l'immobilier avec des rendus réalistes, home staging virtuel et plus encore. Apprenez à tirer parti de ces innovations pour optimiser vos ventes.";
      } else if (
        currentUrl.includes(
          "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes"
        )
      ) {
        newDescription =
          "Découvrez comment l'IA aide les agents immobiliers à vendre plus efficacement, de la sélection d'images à la création de rendus de rénovation. Lisez notre article pour en savoir plus!";
      } else if (currentUrl.includes("/fr/blog/exemples-de-home-staging")) {
        newDescription =
          "Découvrez des exemples de home staging avant et après pour des cuisines, salles de bain, chambres, salons et terrasses. Apprenez comment transformer vos espaces avec l'IA et le home staging virtuel.";
      } else if (
        currentUrl.includes(
          "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere"
        )
      ) {
        newDescription =
          "Découvrez comment choisir et optimiser la photo de couverture de votre annonce immobilière pour attirer l'attention des acheteurs et accélérer la vente. Utilisez des outils comme Pedra pour améliorer vos photos.";
      } else if (
        currentUrl.includes(
          "/fr/blog/comment-ajouter-un-ciel-bleu-aux-photographies-immobilieres"
        )
      ) {
        newDescription =
          "Découvrez comment améliorer vos photos immobilières en rendant le ciel bleu grâce à Photoshop, à des services professionnels, ou à des applications spécialisées comme Pedra";
      } else if (currentUrl.includes("/es/blog/home-staging-ejemplos")) {
        newDescription =
          "Ejemplos de home staging. Fotografías de home staging antes y después.";
      } else if (currentUrl.includes("/blog/home-staging-examples")) {
        newDescription =
          "Home staging examples. Photographies of home staging: before and after.";
      } else if (
        currentUrl.includes(
          "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo"
        )
      ) {
        newDescription =
          "Choose and generate cover photos in your real estate listings that catch attention and help you sell";
      } else if (currentUrl.includes("/help/how-to-furnish-virtually")) {
        newDescription =
          "Learn to virtually furnish rooms and properties. This tutorial will will help you virtually furnish like a pro.";
      } else if (currentUrl.includes("/es/help/como-amueblar-virtualmente")) {
        newDescription =
          "Descubre cómo usar la herramienta de amueblar para amueblar virtualmente tus propiedades. Tutorial paso a paso para un amueblado virtual profesional.";
      } else if (
        currentUrl.includes("empty-rooms-and-remove-objects-with-ai")
      ) {
        newDescription =
          "Empty rooms and remove objects with AI. Empty rooms in seconds with 1 click";
      } else if (
        currentUrl.includes("vacia-habitacion-y-quita-objetos-con-ia")
      ) {
        newDescription =
          "Vacia habitación y quita objetos con IA. Vacia espacios en segundos en 1 click.";
      } else if (currentUrl.includes("renovate-interiors-with-ai-from-phone")) {
        newDescription =
          "Renovate interiors and rooms with AI directly from your phone in seconds and easily";
      } else if (
        currentUrl.includes("renueva-espacios-desde-el-movil-con-ia")
      ) {
        newDescription =
          "Renueva habitaciones con IA desde el móvil en segundos y facilmente";
      } else if (
        currentUrl.includes(
          "/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta"
        )
      ) {
        newDescription =
          "Escoge y genera fotos de portada en tus anuncios inmobiliarios que capten la atención y te ayuden a vender";
      } else if (currentUrl.includes("reviews-and-testimonials")) {
        newDescription =
          newLang === "es"
            ? "Opiniones, testimonios y valoraciones sobre Pedra, herramienta de home staging virtual y mejora de fotografías"
            : newLang === "fr"
            ? "Lisez les témoignages de nos clients satisfaits sur Pedra. Découvrez comment notre outil de home staging virtuel et d'amélioration de photos a transformé leur travail."
            : "Opinions, testimonials and reviews about Pedra, virtual home staging and photography enhancement tool";
      } else if (currentUrl.includes("reviews-and-testimonials")) {
        newDescription =
          newLang === "es"
            ? "Lanzamientos y novedades de Pedra – aplicación de IA para agentes e inmobilarias para generar home staging virtuales"
            : "Releases from Pedra – an AI application for real estate agents and companies to generate virtual home staging proposals";
      } else if (currentUrl.includes("blog")) {
        newDescription =
          newLang === "es"
            ? "Blog sobre Home Staging Virtual y Fotografía Real Estate, creado y mantenido por Pedra"
            : newLang === "fr"
            ? "Découvrez les articles récents de Pedra sur l'utilisation de l'IA dans l'immobilier. Apprenez à améliorer vos photos, accélérer les ventes, et plus encore."
            : "Blog about Virtual Home Staging and Real Estate Photography, created and maintained by Pedra";
      } else if (currentUrl.includes("help/how-to-add-3d-objects")) {
        newDescription =
          "How to add 3D objects and furniture to a room and create a virtual home staging from an image.";
      } else if (currentUrl.includes("/es/help/como-anadir-objetos-3d")) {
        newDescription =
          "Cómo añadir objetos y muebles 3D a una imagen y crear un home staging virtual hiperrealista.";
      } else if (currentUrl.includes("releases")) {
        newDescription =
          newLang === "es"
            ? "Novedades en herramienta de home staging virtual y fotografía inmobiliaria"
            : newLang === "fr"
            ? "Découvrez les mises à jour récentes de Pedra pour mai, juin et juillet 2024 : nouvelles fonctionnalités, améliorations d'interface, et plus encore pour chaque mois."
            : "Releases on virtual home staging tool and real estate photography improvement";
      } else {
        newDescription =
          newLang === "es"
            ? "Crea en un clic renders inmobiliarios, home staging virtuals, planos de planta y más con Pedra."
            : newLang === "fr"
            ? "Créez en un clic des rendus immobiliers, du home staging virtuel, des plans de sol et plus encore avec Pedra."
            : "Create fast real estate renders, virtual home stagings, floor plans and more in one click with Pedra.";
      }

      metaDescription.content = newDescription;
    }

    // Update the title based on the language
    const pageTitle = document.querySelector("title");
    if (pageTitle) {
      const currentUrl = window.location.href;
      let newTitle = "";

      if (currentUrl.includes("floorplan")) {
        newTitle =
          newLang === "es"
            ? "Dibuja planos de planta | Crea planos online"
            : newLang === "fr"
            ? "Outil de Création de Plans d'Étage Facile"
            : "Floor Plan Creator| Easy Floor Plan Tool";
      } else if (currentUrl.includes("blog/sell-more-properties-with-ai")) {
        newTitle = "Sell More Real Estate Properties With AI";
      } else if (currentUrl.includes("add-3d-objects-and-make-sky-blue")) {
        newTitle = "Add 3D objects and make sky blue";
      } else if (currentUrl.includes("anade-objetos-3d-y-cielo-azul")) {
        newTitle = "Añade objetos 3D y pon el cielo azul";
      } else if (currentUrl.includes("help/how-to-renovate-houses-virtually")) {
        newTitle = "How To Create a Virtual Renovation of a House";
      } else if (currentUrl.includes("help/como-renovar-casas-virtualmente")) {
        newTitle = "Cómo Crear Una Renovación Virtual de una Casa";
      } else if (currentUrl.includes("help/how-to-remove-object")) {
        newTitle = "How to Remove Objects from Property Photos";
      } else if (
        [
          "/fr/blog/augmentez-valeur-maison",
          "/blog/increase-home-value",
          "/es/blog/aumenta-valor-vivienda",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Augmentez la valeur de la maison rapidement et facilement"
          : newLang === "es"
          ? "Aumenta el valor de la vivienda rápido y fácil"
          : "Increase Home Value Quickly and Easily";
      } else if (
        [
          "/fr/blog/espaces-multifonctionnels",
          "/blog/multifunctional-spaces",
          "/es/blog/espacios-multifuncionales",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Espaces multifonctionnels: Qu'est-ce que c'est et comment optimiser l'espace?"
          : newLang === "es"
          ? "Espacios Multifuncionales ¿Qué es y cómo optimizar espacio?"
          : "Multifunctional Spaces: What Are They and How to Optimize Space?";
      } else if (
        currentUrl.includes("/fr/help/comment-ajouter-des-objets-3d")
      ) {
        newTitle =
          "Comment Utiliser l'Outil 'Add Object' pour une Mise en Scène Virtuelle";
      } else if (
        currentUrl.includes("/fr/help/comment-renover-des-maisons-en-virtuel")
      ) {
        newTitle = "Comment créer une rénovation virtuelle d'une maison";
      } else if (currentUrl.includes("/fr/help/comment-meubler-en-virtuel")) {
        newTitle =
          "Guide étape par étape pour meubler virtuellement des pièces avec l'outil de mobilier virtuel";
      } else if (currentUrl.includes("/fr/help/comment-supprimer-un-objet")) {
        newTitle = "Comment supprimer des objets des photos de propriété";
      } else if (currentUrl.includes("es/help/como-borrar-objeto")) {
        newTitle = "Cómo borrar objetos de fotos de casas y propiedades";
      } else if (currentUrl.includes("interiordesign")) {
        newTitle =
          newLang === "es"
            ? "Diseño de Interiores Online | Pruébalo Gratis"
            : newLang === "fr"
            ? "Pedra | Outil de Design d'Intérieur Facile et Rapide"
            : "Pedra | Easy Render Tool For Interior Design";
      } else if (currentUrl.includes("real-estate-photography")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Mejora tus fotografías inmobiliarias con IA"
            : newLang === "fr"
            ? "Méliorez vos photos immobilières en un clic"
            : "Pedra | Improve your real estate photos with AI";
      } else if (currentUrl.includes("realestate")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Herramienta Renders Para Inmobiliarias"
            : newLang === "fr"
            ? "Optimisez vos Annonces Immobilières avec Pedra"
            : "Pedra | Easy Render Tool For Real Estate";
      } else if (currentUrl.includes("como-vender-casa")) {
        newTitle =
          newLang === "es"
            ? "¿Cómo vender mi casa? | Consejos para vender rápido tu casa"
            : "Pedra | Easy Render Tool For Real Estate";
      } else if (currentUrl.includes("renovar-casa")) {
        newTitle =
          newLang === "es"
            ? "¿Cómo renovar tu casa? | Pasos e ideas para renovar tu hogar"
            : "Pedra | Easy Render Tool For Real Estate";
      } else if (currentUrl.includes("pricing")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Precios"
            : newLang === "fr"
            ? "Tarifs Pedra: Forfait Pro à 29€/mois"
            : "Pedra | Pricing";
      } else if (currentUrl.includes("render")) {
        newTitle =
          newLang === "es"
            ? "Renders IA | Crea renders con IA "
            : newLang === "fr"
            ? "Rendus Immobiliers IA – Transformez Vos Images en Rendus Professionnels avec IA"
            : "AI Renders | Create AI renders";
      } else if (
        currentUrl.includes("/blog/how-to-make-sky-blue-real-estate-photo")
      ) {
        newTitle = "How To Turn Sky Blue In Real Estate Photo";
      } else if (
        currentUrl.includes(
          "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia"
        )
      ) {
        newTitle = "IA dans l'immobilier : Applications et Avantages";
      } else if (
        currentUrl.includes(
          "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes"
        )
      ) {
        newTitle = "Comment l'IA Révolutionne la Vente Immobilière";
      } else if (currentUrl.includes("/fr/blog/exemples-de-home-staging")) {
        newTitle = "Exemples de Home Staging : Avant et Après";
      } else if (
        currentUrl.includes(
          "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere"
        )
      ) {
        newTitle =
          "Comment la photo de couverture peut accélérer la vente de votre propriété";
      } else if (
        currentUrl.includes(
          "/fr/blog/comment-ajouter-un-ciel-bleu-aux-photographies-immobilieres"
        )
      ) {
        newTitle = "Comment rendre le ciel bleu dans vos photos immobilières";
      } else if (
        currentUrl.includes(
          "/es/blog/como-poner-cielo-azul-fotografias-de-inmuebles"
        )
      ) {
        newTitle = "Cómo Poner Cielo Azul En Fotos de Inmuebles";
      } else if (
        currentUrl.includes("como-utilizar-ia-para-vender-propiedades") ||
        currentUrl.includes("how-to-sell-properties-with-ai")
      ) {
        newTitle =
          newLang === "es"
            ? "¿Cómo usar la IA en Inmobiliarias? | IA para inmobiliarias"
            : "What is AI and how is it used in Real Estate? | AI for Real Estate";
      } else if (currentUrl.includes("/help/how-to-furnish-virtually")) {
        newTitle =
          "Step-by-Step Guide to Virtually Furnish Rooms with Virtual Furnishing Tool";
      } else if (currentUrl.includes("/es/help/como-amueblar-virtualmente")) {
        newTitle =
          "Tutorial de Amueblado Virtual: Guía Paso a Paso para Amueblar Virtualmente";
      } else if (currentUrl.includes("home-staging-virtual")) {
        newTitle =
          newLang === "es"
            ? "Home Staging Virtual | Crear un home staging virtual"
            : newLang === "fr"
            ? "Home Staging Virtuel | Créer un home staging virtuel"
            : "Virtual Home Staging | Create a virtual home staging";
      } else if (
        currentUrl.includes("vende-mas-propiedades-inmobiliarias-con-ia")
      ) {
        newTitle =
          newLang === "es"
            ? "¿Cómo puede la IA ayudar a los agentes inmobiliarios a vender?"
            : "How can I help real estate agents sell?";
      } else if (
        currentUrl.includes("vende-mas-propiedades-inmobiliarias-con-ia")
      ) {
        newTitle =
          newLang === "es"
            ? "¿Cómo puede la IA ayudar a los agentes inmobiliarios a vender?"
            : "How can I help real estate agents sell?";
      } else if (currentUrl.includes("privacy")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Política de Privacidad"
            : newLang === "fr"
            ? "Politique de Confidentialité - Pedra.so"
            : "Pedra | Privacy Policy";
      } else if (currentUrl.includes("legal")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Aviso Legal"
            : newLang === "fr"
            ? "Mentions Légales - Pedra.so"
            : "Pedra | Legal Notice";
      } else if (currentUrl.includes("renovate-interiors-with-ai-from-phone")) {
        newTitle = "Renovate interiors with AI with phone";
      } else if (
        currentUrl.includes("renueva-espacios-desde-el-movil-con-ia")
      ) {
        newTitle = "Renueva habitaciones con IA desde el móvil";
      } else if (
        currentUrl.includes("empty-rooms-and-remove-objects-with-ai")
      ) {
        newTitle = "Empty rooms and remove objects with AI";
      } else if (currentUrl.includes("/es/blog/home-staging-ejemplos")) {
        newTitle = "Ejemplos de Home Staging | Antes y Después";
      } else if (currentUrl.includes("/blog/home-staging-examples")) {
        newTitle = "Home Staging Examples | Before and After";
      } else if (
        currentUrl.includes("vacia-habitacion-y-quita-objetos-con-ia")
      ) {
        newTitle = "Vacia habitación y quita objetos con IA";
      } else if (currentUrl.includes("about")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Sobre Nosotros"
            : newLang === "fr"
            ? "À Propos de Pedra - Outil de home staging virtuel"
            : "Pedra | About Us";
      } else if (
        currentUrl.includes(
          "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo"
        )
      ) {
        newTitle = "How To Improve Real Estate Listing Cover";
      } else if (
        currentUrl.includes(
          "/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta"
        )
      ) {
        newTitle = "Cómo Mejorar La Foto De Portada Del Anuncio Inmobiliario";
      } else if (currentUrl.includes("es/mls")) {
        newTitle = "Pedra y MLS| Herramienta Inmobiliaria Con IA Para La MLS";
      } else if (currentUrl.includes("releases")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Novedades en herramienta de home staging virtual"
            : newLang === "fr"
            ? "Dernières Versions de Pedra – Juillet, Juin et Mai 2024"
            : "Pedra | Releases on virtual home staging tool";
      } else if (currentUrl.includes("reviews-and-testimonials")) {
        newTitle =
          newLang === "es"
            ? "Testimonios para Pedra | Opiniones y Valoraciones"
            : newLang === "fr"
            ? "Témoignages Pedra | Découvrez les Avis de Nos Clients"
            : "Testimonials for Pedra | Reviews and Opinions";
      } else if (currentUrl.includes("blog")) {
        newTitle =
          newLang === "es"
            ? "Blog de Pedra | Virtual Home Staging y Fotografía Inmobiliaria"
            : newLang === "fr"
            ? "Blog de Pedra – Conseils et Astuces pour l'Immobilier avec l'IA"
            : "Pedra's Blog | Home Staging Virtual and Real Estate Photography";
      } else if (currentUrl.includes("help/how-to-add-3d-objects")) {
        newTitle = "Add Object to Create a Virtual Home Staging";
      } else if (currentUrl.includes("/es/help/como-anadir-objetos-3d")) {
        newTitle = "Añadir Objeto para Crear un Home Staging Virtual";
      } else {
        newTitle =
          newLang === "es"
            ? "Pedra | Renders IA, Home Staging Virtual, Fotografía Inmobiliaria"
            : newLang === "fr"
            ? "Pedra | Rendus IA, Home Staging Virtuel, Photographie Immobilière"
            : "Pedra | AI Render, Virtual Home Staging, Real Estate Photography";
      }

      pageTitle.innerText = newTitle;
    }
  }, [location]);

  return null; // This component doesn't render anything
};

export default LangManager;
