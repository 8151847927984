import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1Es from "../Images/HowToRenovateFrame1Es.jpg";
import HowToRenovateFrame2Es from "../Images/HowToRenovateFrame2Es.jpg";
import HowToRenovateFrame3Es from "../Images/HowToRenovateFrame3Es.jpg";
import InfoIcon from "../Icons/InfoIcon";

function HowToRenovateEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo usar la herramienta de renovación para renovar casas
            virtualmente
          </h1>

          <div className="title-secondary gray">
            Tutorial sobre cómo renovar casas virtualmente
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Para qué sirven las herramientas de renovar de Pedra?
            </h2>
            <p className="article-text">
              Con las herramientas de renovación en Pedra, podrás mostrar una
              potencial reforma de una propiedad en pocos segundos.
              <p style={{ marginTop: "10px" }}>
                Mostrar el potencial de una reforma es ideal para ayudar en la
                venta de propiedades de segudna mano, que seguramente
                necesitaran una buenva inversión por parte del comprador para
                dejarla en mejor estado.
              </p>
              <p style={{ marginTop: "10px" }}>
                Gracias a Pedra, no hace falta que contrates a rendersitas o a
                arquitectos para tener una propuesta de renovación de una
                propiedad.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              ¿Cómo creo una propuesta de renovación con Pedra?
            </h2>
            <p className="article-text">
              Una vez hayas subido las imágenes que te interesa renovar,
              selecciónalas.
              <p style={{ marginTop: "10px" }}>
                Haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span>, y
                verás la opción de{" "}
                <span style={{ fontWeight: "500" }}>"Renovación"</span> y{" "}
                <span style={{ fontWeight: "500" }}>"Renovación avanzada"</span>
                .
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1Es}
                alt="Cómo usar la herramienta de renovación para renovar casas virtualmente – Captura del software Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Renovación: </span>
              Genera una renovación virtual rápida
            </h2>
            <p style={{ marginTop: "10px" }}>
              Haciendo 1 click en{" "}
              <span style={{ fontWeight: "500" }}>"Renovación"</span> generarás
              una propuesta de renovación de las imágenes que hayas
              seleccionado. En unos 15 segundos las tendrás.
              <p>
                Por defecto, la opción de{" "}
                <span style={{ fontWeight: "500" }}>"Renovación"</span>
                genera una renovación minimalista con un nivel de cambios poco
                agresivo.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2Es}
                alt="Cómo usar la herramienta de renovación para renovar casas virtualmente – Captura del software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Renovación avanzada: </span>
              Genera una renovación virtual a tu gusto
            </h2>
            <p className="article-text">
              Con Pedra también tienes la opción de "Renovación avanzada".
              <p style={{ marginTop: "10px" }}>
                Al igual que{" "}
                <span style={{ fontWeight: "500" }}>"Renovación"</span>, podrás
                generar propuestas de reforma en segundos.
                <p style={{ marginTop: "10px" }}>
                  Pero además, podrás escoger el estilo de renovación, el grado
                  de creatividad de los cambios e incluso si preservar ciertas
                  partes de la imagen original, como por ejemplo las ventanas.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3Es}
                  alt="Cómo usar la herramienta de renovación para renovar casas virtualmente – Captura del software Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              {" "}
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 1 – </span>
              Estilo de renovación avanzada
            </h3>
            <p className="article-text">
              Pedra ofrece 9 estilos básicos de renovación avanzada:
              minimalista, tradicional, mediterráneo, etc.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "600" }}>
                  También puedes subir tu propio estilo de renovación.
                </span>{" "}
                Al subir tu propio estilo de renovación, podrás crear propuestas
                de renovación basadas en el estilo que hayas subido.
              </p>
              <p className="article-text" style={{ marginTop: "10px" }}>
                A continuación, te mostramos un video de 40 segundos que explica
                cómo funciona:
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px auto",
                }}
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/xMm8hKmSMSA?si=TpCyOIZDhtFHm-r1&amp;controls=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 2 – </span>
              Creatividad de renovación avanzada
            </h3>
            <p className="article-text">
              También puedes escoger el grado de renovación avanzada entre Baja,
              Media, Alta y Muy Alta.
              <p style={{ marginTop: "10px" }}>
                Baja y Media conservaran bien la arquitectura de la fotografía
                original.
              </p>
              <p style={{ marginTop: "10px" }}>
                Alta y Muy Alta está pensado para que cambie agresivamente la
                arquitectura del espacio, de tal manera que la fotografía
                resultante no se parezca a la original.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 3 – </span>
              Preservar ventanas en la renovación avanzada
            </h3>
            <p className="article-text">
              También tienes la opción de preservar las ventanas cuando hagas
              una renovación avanzada. Eso es importante si quieres mantener las
              vistas de las ventanas, o si quieres que sean exactamente las
              mismas ventanas que en la fotografía original.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 4 – </span>
              Generar imagen de la renovación avanzada
            </h2>
            <p>
              Una vez hayas seleccionado el estilo, creatividad y si preservar o
              no las ventanas, podrás generar las imágenes. Tardará unos 15
              segundos cada imagen, a no ser que preserves ventanas, que tardará
              un poco más.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Cómo evitar cambios no deseados en ventanas y puertas
                </h4>
                <p className="callout-p">
                  Si en las imágenes generadas aparecen puertas donde habían
                  ventanas, o más ventanas de la cuenta, te proponemos las
                  siguientes acciones:
                  <ul>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Genera 2 o 3 renovaciones adicionales
                      </span>
                      . Cada renovación es única, así que habrá alguna que
                      conserve mejor las ventanas y puertas que las demás.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      {" "}
                      Mantén el grado de creatividad bajo.
                    </li>
                    <li>
                      {" "}
                      <span style={{ fontWeight: "500" }}>
                        Si en la fotografía original hay reflejos o espejos que
                        se confunden con ventanas, usa la herramienta de para
                        quitarlos de la imagen
                      </span>
                      . Luego, genera la renovación sobre la imagen sin reflejos
                      ni espejos.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenovateEs;
