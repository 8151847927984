// App.js
import React, { useState, useEffect } from "react";
import "./App.css";
import { Link } from "react-router-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ReactComponent as Logo } from "./Images/Logo.svg";
import WorldIcon from "./Icons/WorldIcon";
import ScrollToTop from "./ScrollToTop";
import FooterLogo from "./Images/FooterLogo.png";
import LangManager from "./LangManager";

// Pages
import Pricing from "./Pricing";
import PricingEs from "./PricingEs";
import Home from "./Home";
import HomeEs from "./HomeEs";
import FloorPlan from "./FloorPlan";
import FloorPlanEs from "./FloorPlanEs";
import RealEstateAgents from "./RealEstateAgents";
import RealEstateAgentsEs from "./RealEstateAgentsEs";
import InteriorDesign from "./InteriorDesign";
import InteriorDesignEs from "./InteriorDesignEs";
import Render from "./Render";
import RenderEs from "./RenderEs";
import HomeStagingEs from "./HomeStagingEs.js";
import HomeStaging from "./HomeStaging.js";
import NotFound from "./NotFound";
import NotFoundEs from "./NotFoundEs";
import Blog from "./Blog";
import BlogEs from "./BlogEs";
import BlogEsArticle1 from "./BlogArticles/BlogEsArticle1";
import BlogEsArticle2 from "./BlogArticles/BlogEsArticle2";
import BlogEsArticle3 from "./BlogArticles/BlogEsArticle3";
import BlogEsArticle4 from "./BlogArticles/BlogEsArticle4.js";
import BlogEsArticle5 from "./BlogArticles/BlogEsArticle5.js";
import BlogArticle1 from "./BlogArticles/BlogArticle1";
import BlogArticle5 from "./BlogArticles/BlogArticle5.js";
import BlogArticle8 from "./BlogArticles/BlogArticle8.js";
import BlogFrArticle8 from "./BlogArticles/BlogFrArticle8.js";
import RenderIAHeaderIcon from "./Icons/RenderIAHeaederIcon.jsx";
import VirtualStagingAIHeaderIcon from "./Icons/VirtualStagingAIHeaderIcon.jsx";
import FloorPlanHeaderIcon from "./Icons/FloorPlanHeaderIcon.jsx";
import ToggleIconUp from "./Icons/ToggleIconUp.jsx";
import ToggleIconDown from "./Icons/ToggleIconDown.jsx";
import Legal from "./Legal.js";
import LegalEs from "./LegalEs.js";
import Privacy from "./Privacy.js";
import PrivacyEs from "./PrivacyEs.js";
import RealEstatePhotographyEs from "./RealEstatePhotographyEs.js";
import OrangeBlueSky from "./Icons/OrangeBlueSky.jsx";
import RealEstatePhotography from "./RealEstatePhotography.js";
import MlsEs from "./MlsEs.js";
import ReleasesEs from "./ReleasesEs.js";
import ReleaseEsJun24 from "./Releases/ReleaseEsJun24.js";
import Releases from "./Releases.js";
import ReleaseJun24 from "./Releases/ReleaseJun24.js";
import ReleaseMay24 from "./Releases/ReleaseMay24.js";
import ReleaseEsMay24 from "./Releases/ReleaseEsMay24.js";
import AboutUsEs from "./AboutUsEs.js";
import AboutUs from "./AboutUs.js";
import BlogEsArticle6 from "./BlogArticles/BlogEsArticle6.js";
import BlogArticle6 from "./BlogArticles/BlogArticle6.js";
import TestimonialsEs from "./TestimonialsEs.js";
import Testimonials from "./Testimonials.js";
import HowTo3DEs from "./Help/HowTo3DEs.js";
import HowTo3D from "./Help/HowTo3D.js";
import BlogArticle4 from "./BlogArticles/BlogArticle4.js";
import ReleaseEsJul24 from "./Releases/ReleaseEsJul24.js";
import ReleaseJul24 from "./Releases/ReleaseJul24.js";
import HowToRenovateEs from "./Help/HowToRenovateEs.js";
import HowToRenovate from "./Help/HowToRenovate.js";
import HowToFurnishEs from "./Help/HowToFurnishEs.js";
import HowToFurnish from "./Help/HowToFurnish.js";
import SocialMediaIcon1 from "./Icons/SocialMediaIcon1.jsx";
import SocialMediaIcon2 from "./Icons/SocialMediaIcon2.jsx";
import SocialMediaIcon3 from "./Icons/SocialMediaIcon3.jsx";
import SocialMediaIcon4 from "./Icons/SocialMediaIcon4.jsx";
import BlogEsArticle7 from "./BlogArticles/BlogEsArticle7.js";
import BlogArticle7 from "./BlogArticles/BlogArticle7.js";
import HowToRemoveObjectEs from "./Help/HowToRemoveObjectEs.js";
import HowToRemoveObject from "./Help/HowToRemoveObject.js";
import HomeFr from "./HomeFr.js";
import HowToRenovateFr from "./Help/HowToRenovateFr.js";
import HowToFurnishFr from "./Help/HowToFurnishFr.js";
import HowToRemoveObjectFr from "./Help/HowToRemoveObjectFr.js";
import HowTo3DFr from "./Help/HowTo3DFr.js";
import PrivacyFr from "./PrivacyFr.js";
import LegalFr from "./LegalFr.js";
import NotFoundFr from "./NotFoundFr.js";
import BlogFrArticle1 from "./BlogArticles/BlogFrArticle1.js";
import BlogFrArticle4 from "./BlogArticles/BlogFrArticle4.js";
import BlogFrArticle5 from "./BlogArticles/BlogFrArticle5.js";
import BlogFrArticle6 from "./BlogArticles/BlogFrArticle6.js";
import BlogFrArticle7 from "./BlogArticles/BlogFrArticle7.js";
import ReleaseJul24Fr from "./Releases/ReleaseFrJul24.js";
import ReleaseJun24Fr from "./Releases/ReleaseFrJun24.js";
import ReleaseMay24Fr from "./Releases/ReleaseFrMay24.js";
import ReleasesFr from "./ReleasesFr.js";
import HomeStagingFr from "./HomeStagingFr.js";
import RenderFr from "./RenderFr.js";
import FloorPlanFr from "./FloorPlanFr.js";
import RealEstatePhotographyFr from "./RealEstatePhotographyFr.js";
import RealEstateAgentsFr from "./RealEstateAgentsFr.js";
import AboutUsFr from "./AboutUsFr.js";
import PricingFr from "./PricingFr.js";
import BlogFr from "./BlogFr.js";
import TestimonialsFr from "./TestimonialsFr.js";
import InteriorDesignFr from "./InteriorDesignFr.js";
import BlogEsArticle8 from "./BlogArticles/BlogEsArticle8.js";
import BlogEsArticle9 from "./BlogArticles/BlogEsArticle9.js";
import BlogFrArticle9 from "./BlogArticles/BlogFrArticle9.js";
import BlogArticle9 from "./BlogArticles/BlogArticle9.js";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function App() {
  // States to handle page interactions
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [productHover, setProductHover] = useState(false);
  const [languageHover, setLanguageHover] = useState(false);

  // Determine initial locale and locale path based on URL
  const initialLocale = window.location.pathname.startsWith("/es")
    ? "es"
    : window.location.pathname.startsWith("/fr")
    ? "fr"
    : "en";
  const initialLocalePath =
    initialLocale === "en" ? "/" : initialLocale === "fr" ? "fr/" : "es/";
  const [locale, setLocale] = useState(initialLocale);
  const [localePath, setLocalePath] = useState(initialLocalePath);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      <LangManager />
      <div className="App">
        <header>
          <Link
            to={`/${locale === "en" ? "" : locale}`}
            style={{ height: "40px", marginLeft: "0px" }}
          >
            <Logo height="40" width="100" style={{ marginLeft: "10px" }} />
          </Link>

          <div>
            {windowWidth > 625 && (
              <div
                className="button-small-secondary"
                onMouseEnter={() => setProductHover(true)}
                onMouseLeave={() => setProductHover(false)}
              >
                {locale === "en" ? (
                  <>Product</>
                ) : locale === "es" ? (
                  <>Producto</>
                ) : (
                  <>Outils</>
                )}
                {productHover ? <ToggleIconUp /> : <ToggleIconDown />}
              </div>
            )}
          </div>

          <div>
            {windowWidth > 625 && (
              <Link
                className="button-small-secondary"
                to={`${localePath}pricing`}
                hrefLang={
                  locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                }
              >
                {locale === "en" ? (
                  <>Pricing</>
                ) : locale === "es" ? (
                  <>Precio</>
                ) : (
                  <>Tarifs</>
                )}
              </Link>
            )}
          </div>

          <div className="header-spacer"></div>

          <button
            className="button-small-secondary"
            onMouseEnter={() => setLanguageHover(true)}
            onMouseLeave={() => setLanguageHover(false)}
          >
            <span
              style={{
                color: "#828282",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <WorldIcon />
            </span>
            <span>
              {locale === "en"
                ? " English"
                : locale === "es"
                ? "Español"
                : "Français"}
            </span>
            {languageHover && (
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                  top: "45px",
                  zIndex: 2,
                  width: "120px",
                  backgroundColor: "#fff",
                  padding: "4px",
                  borderRadius: "8px",
                  border: "1px solid #e0e0e0",
                  boxShadow:
                    "rgba(15, 15, 15, 0.1) 0px 3px 6px 0, rgba(15, 15, 15, 0.2) 0px 9px 24px 0px",
                }}
                onMouseEnter={() => setLanguageHover(true)}
                onMouseLeave={() => setLanguageHover(false)}
              >
                <Link
                  to={""}
                  style={{ padding: "0" }}
                  onClick={
                    (() => setLocale("en"),
                    () => setLocalePath("/"),
                    () => (window.location.href = "/"))
                  }
                >
                  <div className="product-header-submenu-page-container">
                    <div className="product-header-title-subtitle-container">
                      <div className="product-header-title">English</div>
                    </div>
                  </div>
                </Link>

                <Link
                  to={"es"}
                  style={{ padding: "0" }}
                  onClick={
                    (() => setLocale("es"),
                    () => setLocalePath("/es"),
                    () => (window.location.href = "/es"))
                  }
                >
                  <div className="product-header-submenu-page-container">
                    <div className="product-header-title-subtitle-container">
                      <div className="product-header-title">Español</div>
                    </div>
                  </div>
                </Link>

                <Link
                  to={"fr"}
                  style={{ padding: "0" }}
                  onClick={
                    (() => setLocale("fr"),
                    () => setLocalePath("/fr"),
                    () => (window.location.href = "/fr"))
                  }
                >
                  <div className="product-header-submenu-page-container">
                    <div className="product-header-title-subtitle-container">
                      <div className="product-header-title">Français</div>
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </button>

          <div className="button-navbar-divider"></div>

          <div className="button-container">
            {windowWidth > 450 && (
              <button
                className="button-small-secondary"
                style={{ lineHeight: "100%" }}
                onClick={redirectToPage}
              >
                {locale === "en" ? (
                  <>Log in</>
                ) : locale === "es" ? (
                  <>Iniciar sesión</>
                ) : (
                  <>Se connecter</>
                )}
              </button>
            )}
            <button className="button-small-header" onClick={redirectToPage}>
              {locale === "en" ? (
                <>Try Pedra</>
              ) : locale === "es" ? (
                <>Prueba Pedra</>
              ) : (
                <>Essayez Pedra</>
              )}
            </button>
          </div>

          {productHover && (
            <div
              style={{
                position: "fixed",
                display: "flex",
                flexDirection: "column",
                gap: "2px",
                top: "45px",
                left: "130px",
                zIndex: 2,
                backgroundColor: "#fff",
                padding: "4px",
                borderRadius: "8px",
                border: "1px solid #e0e0e0",
                boxShadow:
                  "rgba(15, 15, 15, 0.1) 0px 3px 6px 0, rgba(15, 15, 15, 0.2) 0px 9px 24px 0px",
              }}
              onMouseEnter={() => setProductHover(true)}
              onMouseLeave={() => setProductHover(false)}
            >
              <Link
                to={`${localePath}render`}
                hrefLang={
                  locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                }
                style={{ padding: "0" }}
              >
                <div className="product-header-submenu-page-container">
                  <div className="product-header-submenu-icon-container">
                    <RenderIAHeaderIcon />
                  </div>
                  <div className="product-header-title-subtitle-container">
                    <div className="product-header-title">
                      {locale === "en" ? (
                        <>AI Render</>
                      ) : locale === "es" ? (
                        <>Render IA</>
                      ) : (
                        <>Rendu IA</>
                      )}
                    </div>
                    <div className="product-header-subtitle">
                      {locale === "en" ? (
                        <>Create renders in 1 click</>
                      ) : locale === "es" ? (
                        <>Crear renders en 1 click</>
                      ) : (
                        <>Créer rendus dans 1 click</>
                      )}
                    </div>
                  </div>
                </div>
              </Link>

              <Link
                to={`${localePath}home-staging-virtual`}
                hrefLang={
                  locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                }
                style={{ padding: "0" }}
              >
                <div className="product-header-submenu-page-container">
                  <div className="product-header-submenu-icon-container">
                    <VirtualStagingAIHeaderIcon />
                  </div>
                  <div className="product-header-title-subtitle-container">
                    <div className="product-header-title">
                      {locale === "en" ? (
                        <>Virtual home staging</>
                      ) : locale === "es" ? (
                        <>Home staging virtual</>
                      ) : (
                        <>Home staging virtuel</>
                      )}
                    </div>
                    <div className="product-header-subtitle">
                      {locale === "en" ? (
                        <>Furnish empty spaces with virtual staging</>
                      ) : locale === "es" ? (
                        <>Amuebla espacios vacios con home staging virtual</>
                      ) : (
                        <>
                          Meublez d'éspaces vides avec mise en scène virtuelle
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Link>

              <Link
                to={`${localePath}floorplan`}
                hrefLang={
                  locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                }
                style={{ padding: "0" }}
              >
                <div className="product-header-submenu-page-container">
                  <div className="product-header-submenu-icon-container">
                    <FloorPlanHeaderIcon />
                  </div>
                  <div className="product-header-title-subtitle-container">
                    <div className="product-header-title">
                      {locale === "en" ? (
                        <>Floor plan</>
                      ) : locale === "es" ? (
                        <>Planos de planta</>
                      ) : (
                        <>Plan d'étage</>
                      )}
                    </div>
                    <div className="product-header-subtitle">
                      {locale === "en" ? (
                        <>Draw floor plans fast and easy</>
                      ) : locale === "es" ? (
                        <>Dibuja planos rápido y facilmente</>
                      ) : (
                        <>Dessinez des plans d'étage</>
                      )}
                    </div>
                  </div>
                </div>
              </Link>

              <Link
                to={`${localePath}real-estate-photography`}
                hrefLang={
                  locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                }
                style={{ padding: "0" }}
              >
                <div className="product-header-submenu-page-container">
                  <div className="product-header-submenu-icon-container">
                    <OrangeBlueSky />
                  </div>
                  <div className="product-header-title-subtitle-container">
                    <div className="product-header-title">
                      {locale === "en" ? (
                        <>Real estate photography</>
                      ) : locale === "es" ? (
                        <>Fotografía inmobiliaria</>
                      ) : (
                        <>Photos immobilières</>
                      )}
                    </div>
                    <div className="product-header-subtitle">
                      {locale === "en" ? (
                        <>Improve you real estate photos with AI</>
                      ) : locale === "es" ? (
                        <>Mejora tus fotografías inmobiliarias con IA</>
                      ) : (
                        <>Perfectionnez facilement vos photos immobilières</>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          )}
        </header>

        <ScrollToTop />
        <Routes>
          {/* Re-direct routes */}
          <Route
            path="/es/homestagingai"
            element={<Navigate to="/es/home-staging-virtual" replace />}
          />
          <Route
            path="/fr/homestagingai"
            element={<Navigate to="/fr/home-staging-virtual" replace />}
          />
          <Route
            path="/homestagingai"
            element={<Navigate to="/home-staging-virtual" replace />}
          />
          {/* Default routes */}
          <Route path="/" element={<Home locale={locale} />} />
          <Route path="/interiordesign" element={<InteriorDesign />} />
          <Route path="/realestate" element={<RealEstateAgents />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/floorplan" element={<FloorPlan />} />
          <Route path="/render" element={<Render />} />
          <Route path="/home-staging-virtual" element={<HomeStaging />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route
            path="/real-estate-photography"
            element={<RealEstatePhotography />}
          />
          <Route
            path="/blog/how-to-sell-properties-with-ai"
            element={<BlogArticle1 />}
          />
          <Route
            path="/blog/sell-more-properties-with-ai"
            element={<BlogArticle4 />}
          />
          <Route
            path="/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo"
            element={<BlogArticle5 />}
          />
          <Route
            path="/blog/how-to-make-sky-blue-real-estate-photo"
            element={<BlogArticle6 />}
          />
          <Route
            path="/blog/home-staging-examples"
            element={<BlogArticle7 />}
          />
          <Route path="/releases" element={<Releases />} />
          <Route
            path="/releases/renovate-interiors-with-ai-from-phone"
            element={<ReleaseJun24 />}
          />
          <Route
            path="/releases/empty-rooms-and-remove-objects-with-ai"
            element={<ReleaseMay24 />}
          />
          <Route
            path="/releases/add-3d-objects-and-make-sky-blue"
            element={<ReleaseJul24 />}
          />
          <Route
            path="/help/how-to-renovate-houses-virtually"
            element={<HowToRenovate />}
          />
          <Route
            path="/help/how-to-furnish-virtually"
            element={<HowToFurnish />}
          />
          <Route
            path="/help/how-to-remove-object"
            element={<HowToRemoveObject />}
          />
          <Route path="/help/how-to-add-3d-objects" element={<HowTo3D />} />
          <Route path="/about" element={<AboutUs />} />
          <Route
            path="/blog/multifunctional-spaces"
            element={<BlogArticle8 />}
          />
          <Route path="/blog/increase-home-value" element={<BlogArticle9 />} />
          <Route path="/*" element={<NotFound />} />
          {/* Spanish routes */}
          <Route path="/es" element={<HomeEs locale={locale} />} />
          <Route path="/es/interiordesign" element={<InteriorDesignEs />} />
          <Route path="/es/realestate" element={<RealEstateAgentsEs />} />
          <Route path="/es/pricing" element={<PricingEs />} />
          <Route path="/es/floorplan" element={<FloorPlanEs />} />
          <Route path="/es/render" element={<RenderEs />} />
          <Route path="/es/home-staging-virtual" element={<HomeStagingEs />} />
          <Route path="/es/blog" element={<BlogEs />} />
          <Route path="/es/legal" element={<LegalEs />} />
          <Route path="/es/privacy" element={<PrivacyEs />} />
          <Route
            path="/es/real-estate-photography"
            element={<RealEstatePhotographyEs />}
          />
          <Route
            path="/es/blog/como-utilizar-ia-para-vender-propiedades"
            element={<BlogEsArticle1 />}
          />
          <Route path="/reviews-and-testimonials" element={<Testimonials />} />
          <Route
            path="/es/blog/como-vender-casa"
            element={<BlogEsArticle2 />}
          />
          <Route path="/es/blog/renovar-casa" element={<BlogEsArticle3 />} />
          <Route
            path="/es/blog/vende-mas-propiedades-inmobiliarias-con-ia"
            element={<BlogEsArticle4 />}
          />
          <Route
            path="/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta"
            element={<BlogEsArticle5 />}
          />
          <Route
            path="/es/blog/como-poner-cielo-azul-fotografias-de-inmuebles"
            element={<BlogEsArticle6 />}
          />
          <Route
            path="/es/blog/home-staging-ejemplos"
            element={<BlogEsArticle7 />}
          />
          <Route path="/es/mls" element={<MlsEs />} />
          <Route path="/es/releases" element={<ReleasesEs />} />
          <Route
            path="/es/releases/vacia-habitacion-y-quita-objetos-con-ia"
            element={<ReleaseEsMay24 />}
          />
          <Route
            path="/es/releases/renueva-espacios-desde-el-movil-con-ia"
            element={<ReleaseEsJun24 />}
          />
          <Route
            path="/es/releases/anade-objetos-3d-y-cielo-azul"
            element={<ReleaseEsJul24 />}
          />
          <Route path="/es/*" element={<NotFoundEs />} />
          <Route path="/es/about" element={<AboutUsEs />} />
          <Route
            path="/es/reviews-and-testimonials"
            element={<TestimonialsEs />}
          />
          <Route
            path="/es/help/como-anadir-objetos-3d"
            element={<HowTo3DEs />}
          />
          <Route
            path="/es/help/como-renovar-casas-virtualmente"
            element={<HowToRenovateEs />}
          />
          <Route
            path="/es/help/como-amueblar-virtualmente"
            element={<HowToFurnishEs />}
          />
          <Route
            path="/es/help/como-borrar-objeto"
            element={<HowToRemoveObjectEs />}
          />
          <Route
            path="/es/blog/espacios-multifuncionales"
            element={<BlogEsArticle8 />}
          />
          <Route
            path="/es/blog/aumenta-valor-vivienda"
            element={<BlogEsArticle9 />}
          />
          {/* French routes */}
          <Route path="/fr" element={<HomeFr />} />
          <Route
            path="/fr/help/comment-ajouter-des-objets-3d"
            element={<HowTo3DFr />}
          />
          <Route
            path="/fr/help/comment-renover-des-maisons-en-virtuel"
            element={<HowToRenovateFr />}
          />
          <Route
            path="/fr/help/comment-meubler-en-virtuel"
            element={<HowToFurnishFr />}
          />
          <Route
            path="/fr/help/comment-supprimer-un-objet"
            element={<HowToRemoveObjectFr />}
          />
          <Route path="/fr/privacy" element={<PrivacyFr />} />
          <Route path="/fr/legal" element={<LegalFr />} />
          <Route path="/fr/*" element={<NotFoundFr />} />
          <Route
            path="/fr/releases/vide-chambres-et-enlever-objets-avec-ia"
            element={<ReleaseMay24Fr />}
          />
          <Route
            path="/fr/releases/renovation-des-interieurs-avec-lia-depuis-le-telephone"
            element={<ReleaseJun24Fr />}
          />
          <Route
            path="/fr/releases/ajouter-des-objets-3d-et-un-ciel-bleu"
            element={<ReleaseJul24Fr />}
          />
          <Route
            path="/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia"
            element={<BlogFrArticle1 />}
          />
          <Route
            path="/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes"
            element={<BlogFrArticle4 />}
          />
          <Route
            path="/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere"
            element={<BlogFrArticle5 />}
          />
          <Route
            path="/fr/blog/comment-ajouter-un-ciel-bleu-aux-photographies-immobilieres"
            element={<BlogFrArticle6 />}
          />
          <Route
            path="/fr/blog/exemples-de-home-staging"
            element={<BlogFrArticle7 />}
          />
          <Route path="/fr/releases" element={<ReleasesFr />} />
          <Route path="/fr/home-staging-virtual" element={<HomeStagingFr />} />
          <Route path="/fr/pricing" element={<PricingFr />} />
          <Route path="/fr/render" element={<RenderFr />} />
          <Route path="/fr/blog" element={<BlogFr />} />
          <Route path="/fr/floorplan" element={<FloorPlanFr />} />
          <Route path="/fr/interiordesign" element={<InteriorDesignFr />} />
          <Route
            path="/fr/reviews-and-testimonials"
            element={<TestimonialsFr />}
          />
          <Route
            path="/fr/real-estate-photography"
            element={<RealEstatePhotographyFr />}
          />
          <Route path="/fr/realestate" element={<RealEstateAgentsFr />} />
          <Route path="/fr/about" element={<AboutUsFr />} />
          <Route
            path="/fr/blog/espaces-multifonctionnels"
            element={<BlogFrArticle8 />}
          />
          <Route
            path="/fr/blog/augmentez-valeur-maison"
            element={<BlogFrArticle9 />}
          />
        </Routes>

        <div className="long-break"> </div>
        <div className="long-break"> </div>
        <div className="section-one-column-left">
          <footer>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
              }}
            >
              <div
                style={{
                  marginTop: "0px",
                  alignItems: "top",
                }}
              >
                <img src={FooterLogo} height="30" alt="Pedra logo" />
              </div>

              <p
                style={{
                  marginRight: "16px",
                  marginBottom: "16px",
                  display: "flex",
                  textAlign: "left",
                  color: "#333",
                  width: "140px",
                }}
              >
                Pedra Software SL <br />
                &copy; 2024
              </p>
              <p style={{ display: "flex", gap: "16px" }}>
                <a
                  href="https://www.instagram.com/pedrasoftware/"
                  target="_blank"
                  style={{ padding: "0px" }}
                  rel="nofollow noopener noreferrer"
                  title="Instagram"
                >
                  <SocialMediaIcon1 />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCExqYM0ywIYkNu8ecW00kkw"
                  target="_blank"
                  style={{ padding: "0px" }}
                  rel="nofollow noopener noreferrer"
                  title="YouTube"
                >
                  <SocialMediaIcon3 />
                </a>
                <a
                  href="https://www.linkedin.com/company/pedrasoftware/"
                  target="_blank"
                  style={{ padding: "0px" }}
                  rel="nofollow noopener noreferrer"
                  title="LinkedIn"
                >
                  <SocialMediaIcon2 />
                </a>
                <a
                  href="http://twitter.com/PedraSoftware"
                  target="_blank"
                  style={{ padding: "0px" }}
                  rel="nofollow noopener noreferrer"
                  title="Twitter"
                >
                  <SocialMediaIcon4 />
                </a>
              </p>
            </div>

            <div className="header-spacer"></div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
                width: "200px",
              }}
            >
              <div
                style={{ color: "#333333", fontWeight: "600", width: "200px" }}
              >
                {locale === "en" ? (
                  <>Product</>
                ) : locale === "es" ? (
                  <>Producto</>
                ) : (
                  <>Outils</>
                )}
              </div>

              <div>
                <Link
                  to={`${localePath}render`}
                  hrefLang={
                    locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                  }
                  className="article-link footer"
                >
                  {locale === "en" ? (
                    <>AI Render</>
                  ) : locale === "es" ? (
                    <>Render IA</>
                  ) : (
                    <>Rendu IA</>
                  )}
                </Link>
              </div>
              <div>
                <Link
                  to={`${localePath}home-staging-virtual`}
                  hrefLang={
                    locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                  }
                  state={{ lineHeight: "124%" }}
                  className="article-link footer"
                >
                  {locale === "en" ? (
                    <>Virtual home staging</>
                  ) : locale === "es" ? (
                    <>Home staging virtual</>
                  ) : (
                    <>Home staging virtuel</>
                  )}
                </Link>
              </div>
              <div>
                <Link
                  to={`${localePath}real-estate-photography`}
                  hrefLang={
                    locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                  }
                  state={{ lineHeight: "124%" }}
                  className="article-link footer"
                >
                  {locale === "en" ? (
                    <>Real estate photography</>
                  ) : locale === "es" ? (
                    <>Fotografía inmobiliaria</>
                  ) : (
                    <>Photos immobilières</>
                  )}
                </Link>
              </div>
              <div>
                <Link
                  to={`${localePath}floorplan`}
                  hrefLang={
                    locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                  }
                  className="article-link footer"
                >
                  {locale === "en" ? (
                    <>Floor plan</>
                  ) : locale === "es" ? (
                    <>Planos de planta</>
                  ) : (
                    <>Plan d'étage</>
                  )}
                </Link>
              </div>
              <div
                style={{
                  color: "#333333",
                  fontWeight: "600",
                  marginTop: "12px",
                }}
              >
                {locale === "en" ? (
                  <>Resources</>
                ) : locale === "es" ? (
                  <>Recursos</>
                ) : (
                  <>Ressources</>
                )}
              </div>
              <div>
                <Link
                  to={`${localePath}blog`}
                  hrefLang={
                    locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                  }
                  state={{ lineHeight: "124%" }}
                  className="article-link footer"
                >
                  Blog
                </Link>
              </div>
              <div>
                <Link
                  to={`${localePath}releases`}
                  hrefLang={
                    locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                  }
                  state={{ lineHeight: "124%" }}
                  className="article-link footer"
                >
                  {locale === "en" ? (
                    <>Releases</>
                  ) : locale === "es" ? (
                    <>Lanzamientos</>
                  ) : (
                    <>Nouveautés</>
                  )}
                </Link>
              </div>
              <div>
                <Link
                  to={`${localePath}reviews-and-testimonials`}
                  hrefLang={
                    locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                  }
                  className="article-link footer"
                >
                  {locale === "en" ? (
                    <>
                      Reviews{" "}
                      <div style={{ color: "#e9ce45", display: "inline" }}>
                        ★★★★★
                      </div>
                    </>
                  ) : locale === "es" ? (
                    <>
                      Opiniones{" "}
                      <div style={{ color: "#e9ce45", display: "inline" }}>
                        ★★★★★
                      </div>
                    </>
                  ) : (
                    <>
                      Témoignages{" "}
                      <div style={{ color: "#e9ce45", display: "inline" }}>
                        ★★★★★
                      </div>
                    </>
                  )}
                </Link>
              </div>

              <div
                style={{
                  color: "#333333",
                  fontWeight: "600",
                  marginTop: "12px",
                }}
              >
                {locale === "en" ? (
                  <>Articles</>
                ) : locale === "es" ? (
                  <>Artículos</>
                ) : (
                  <>Articles</>
                )}
              </div>
              <div
                style={{
                  width: "120%",
                  lineHeight: "100%",
                  maxWidth: "180px",
                  padding: "4px 0px",
                }}
              >
                <Link
                  to={
                    locale === "en"
                      ? `${localePath}blog/sell-more-properties-with-ai`
                      : locale === "es"
                      ? `${localePath}blog/vende-mas-propiedades-inmobiliarias-con-ia`
                      : `${localePath}blog/vendre-plus-de-proprietes-immobilieres-avec-ia`
                  }
                  hrefLang={
                    locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                  }
                  className="article-link footer"
                >
                  {locale === "en" ? (
                    <>Sell More With AI</>
                  ) : locale === "es" ? (
                    <>Vender Más con IA</>
                  ) : (
                    <>Vendre Plus avec IA</>
                  )}
                </Link>
              </div>
              <div
                style={{
                  width: "120%",
                  lineHeight: "100%",
                  maxWidth: "180px",
                  padding: "4px 0px",
                }}
              >
                <Link
                  to={
                    locale === "en"
                      ? `${localePath}blog/home-staging-examples`
                      : locale === "es"
                      ? `${localePath}blog/home-staging-ejemplos`
                      : `${localePath}blog/exemples-de-home-staging`
                  }
                  hrefLang={
                    locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                  }
                  className="article-link footer"
                >
                  {locale === "en" ? (
                    <>Home Staging Examples</>
                  ) : locale === "es" ? (
                    <>Ejemplos Home Staging</>
                  ) : (
                    <>Exemples Home Staging</>
                  )}
                </Link>
              </div>
            </div>

            <div className="footer-block-group">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                  width: "100%",
                }}
              >
                <div style={{ color: "#333333", fontWeight: "600" }}>
                  {locale === "en" ? (
                    <>Industries</>
                  ) : locale === "es" ? (
                    <>Industrias</>
                  ) : (
                    <>Industries</>
                  )}
                </div>
                <div>
                  <Link
                    to={`${localePath}realestate`}
                    hrefLang={
                      locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                    }
                    className="article-link footer"
                  >
                    Real Estate
                  </Link>
                </div>
                <div>
                  <Link
                    to={`${localePath}interiordesign`}
                    hrefLang={
                      locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en" ? (
                      <>Interior Design</>
                    ) : locale === "es" ? (
                      <>Interiorismo</>
                    ) : (
                      <>Intérieurisme</>
                    )}
                  </Link>
                </div>
                <div
                  style={{
                    color: "#333333",
                    fontWeight: "600",
                    marginTop: "12px",
                  }}
                >
                  {locale === "en" ? (
                    <>Company</>
                  ) : locale === "es" ? (
                    <>Empresa</>
                  ) : (
                    <>Entreprise</>
                  )}
                </div>
                <div>
                  <Link
                    to={`${localePath}about`}
                    hrefLang={
                      locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en" ? (
                      <>About Us</>
                    ) : locale === "es" ? (
                      <>Sobre Nosotros</>
                    ) : (
                      <>À propos</>
                    )}
                  </Link>
                </div>
                <div
                  style={{
                    color: "#333333",
                    fontWeight: "600",
                    marginTop: "12px",
                  }}
                >
                  {locale === "en" ? (
                    <>Help</>
                  ) : locale === "es" ? (
                    <>Ayuda</>
                  ) : (
                    <>Aide</>
                  )}
                </div>
                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}help/how-to-furnish-virtually`
                        : locale === "es"
                        ? `${localePath}help/como-amueblar-virtualmente`
                        : `${localePath}help/comment-meubler-en-virtuel`
                    }
                    hrefLang={
                      locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en" ? (
                      <>How To Furnish</>
                    ) : locale === "es" ? (
                      <>Cómo Amueblar</>
                    ) : (
                      <>Comment Meubler</>
                    )}
                  </Link>
                </div>
                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}help/how-to-renovate-houses-virtually`
                        : locale === "es"
                        ? `${localePath}help/como-renovar-casas-virtualmente`
                        : `${localePath}help/comment-renover-des-maisons-en-virtuel`
                    }
                    hrefLang={
                      locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en" ? (
                      <>How To Renovate</>
                    ) : locale === "es" ? (
                      <>Cómo Renovar</>
                    ) : (
                      <>Comment Renover</>
                    )}
                  </Link>
                </div>
                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}help/how-to-add-3d-objects`
                        : locale === "es"
                        ? `${localePath}help/como-anadir-objetos-3d`
                        : `${localePath}help/comment-ajouter-des-objets-3d`
                    }
                    hrefLang={
                      locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en" ? (
                      <>How To Add Object</>
                    ) : locale === "es" ? (
                      <>Cómo Añadir Objeto</>
                    ) : (
                      <>Comment Ajouter Objets</>
                    )}
                  </Link>
                </div>
                <div>
                  <Link
                    to={
                      locale === "en"
                        ? `${localePath}help/how-to-remove-object`
                        : locale === "es"
                        ? `${localePath}help/como-borrar-objeto`
                        : `${localePath}help/comment-supprimer-un-objet`
                    }
                    hrefLang={
                      locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                    }
                    className="article-link footer"
                  >
                    {locale === "en" ? (
                      <>How To Remove Object</>
                    ) : locale === "es" ? (
                      <>Cómo Borrar Objeto</>
                    ) : (
                      <>Comment Supprimer Objets</>
                    )}
                  </Link>
                </div>
              </div>

              <div
                style={{ display: "flex", flexDirection: "column", gap: "2px" }}
              ></div>

              <div
                style={{ display: "flex", flexDirection: "column", gap: "2px" }}
              >
                <div style={{ color: "#333333", fontWeight: "600" }}>
                  {locale === "en" ? (
                    <>Other</>
                  ) : locale === "es" ? (
                    <>Otros</>
                  ) : (
                    <>Autres</>
                  )}
                </div>
                <div>
                  <Link
                    to={`${localePath}pricing`}
                    hrefLang={
                      locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                    }
                    rel="nofollow"
                    className="article-link footer"
                  >
                    {locale === "en" ? (
                      <>Pricing</>
                    ) : locale === "es" ? (
                      <>Precio</>
                    ) : (
                      <>Tarifs</>
                    )}
                  </Link>
                </div>

                <div>
                  <Link
                    to={`${localePath}legal`}
                    hrefLang={
                      locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                    }
                    rel="nofollow"
                    className="article-link footer"
                  >
                    {locale === "en" ? (
                      <>Legal</>
                    ) : locale === "es" ? (
                      <>Legal</>
                    ) : (
                      <>Légale</>
                    )}
                  </Link>
                </div>

                <div>
                  <Link
                    to={`${localePath}privacy`}
                    hrefLang={
                      locale === "en" ? "en" : locale === "es" ? "es" : "fr"
                    }
                    rel="nofollow"
                    className="article-link footer"
                  >
                    {locale === "en" ? (
                      <>Privacy</>
                    ) : locale === "es" ? (
                      <>Privacidad</>
                    ) : (
                      <>Confidentialitée</>
                    )}
                  </Link>
                </div>

                <div
                  style={{
                    color: "#333333",
                    fontWeight: "600",
                    marginTop: "12px",
                  }}
                >
                  {locale === "en" ? (
                    <>Contact</>
                  ) : locale === "es" ? (
                    <>Contacto</>
                  ) : (
                    <>Contact</>
                  )}
                </div>

                <div style={{ color: "#333" }}>felix@pedra.so</div>
                <div style={{ width: "150px", color: "#333" }}>
                  +34 646 811 068
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </Router>
  );
}

export default App;
