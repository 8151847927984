// InteriorDesign.js
import React from "react";
import { ReactComponent as Frame1HomeBuilders } from "./Images/Frame 1 HomeBuilders.svg";
import { ReactComponent as Frame2HomeBuilders } from "./Images/Frame 2 HomeBuilders.svg";
import { ReactComponent as Frame3HomeBuilders } from "./Images/Frame 3 HomeBuilders.svg";
import ArrowIcon from "./Icons/ArrowIcon.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function InteriorDesignFr() {
  return (
    <div>
      <div>
        <section className="section-first-wrapper">
          <section className="section-first">
            <div className="section-first-text-container">
              <h1 className="section-first-title">
                Pedra pour les Designers d'Intérieur
              </h1>
              <div className="section-first-text">
                Un outil pour les Designers d'Intérieur pour les aider à
                démarrer et vendre des projets de design d'intérieur.
              </div>
              <div className="section-first-button-container">
                <button className="button-small" onClick={redirectToPage}>
                  Essayer Pedra <ArrowIcon />
                </button>
              </div>
            </div>
          </section>
        </section>
        <div style={{ height: "100px", borderTop: "1px solid #EEEEEE" }}></div>
        <section className="project project-alt">
          <div className="picture">
            <Frame1HomeBuilders />
          </div>
          <div className="description">
            <h2> Planification rapide des sols </h2>
            <p className="value-prop-text">
              Oubliez les logiciels difficiles à utiliser comme AutoCAD ou
              Revit.
            </p>
            <p className="value-prop-text">
              Pedra est conçu pour rendre la planification des sols aussi facile
              que le papier et le crayon - tout est fait en 5 minutes !
            </p>
          </div>
        </section>
        <div className="long-break"> </div>
        <section className="project project-alt">
          <div className="picture">
            <Frame2HomeBuilders />
          </div>
          <div className="description">
            <h2> Calculez rapidement les budgets </h2>
            <p className="value-prop-text">
              Suivez le budget de chaque changement : nouveau mur, nouveau sol,
              nouvelle chaise, etc.
            </p>
            <p className="value-prop-text">
              Affichez les prix directement sur le canevas pour une
              communication plus facile avec le client.
            </p>
          </div>
        </section>
        <div className="long-break"> </div>
        <section className="project project-alt">
          <div className="picture">
            <Frame3HomeBuilders />
          </div>
          <div className="description">
            <h2> Générez n'importe quelle image en quelques secondes </h2>
            <p className="value-prop-text">
              Votre client veut visualiser des idées de design ou de rénovation
              de maison, mais les rendus 3D prennent du temps et coûtent
              beaucoup d'argent.
            </p>
            <p className="value-prop-text">
              Avec les fonctionnalités d'IA de Pedra, vous pouvez visualiser une
              pièce vide, une rénovation, ou un changement de couleur de mur en
              un clic.
            </p>
          </div>
        </section>
        <div className="long-break"> </div>
        <div className="centered-heading">
          <button className="button" onClick={redirectToPage}>
            Essayer Pedra
          </button>
        </div>
      </div>
    </div>
  );
}

export default InteriorDesignFr;
