import React from "react";
import ReleaseJul2024Es from "./Images/ReleaseJul2024Es.png";
import ReleaseJun2024Es from "./Images/ReleaseJun2024Es.png";
import ReleaseMay2024Es from "./Images/ReleaseMay2024Es.png";
import { Link } from "react-router-dom";

function ReleasesEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container" style={{ marginTop: "100px" }}>
          <h2 className="title-container">Lanzamientos</h2>
          <div className="title-secondary">
            Ve aquí los últimos lanzamientos de Pedra <br />
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>JULIO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/releases/anade-objetos-3d-y-cielo-azul"
              >
                Pedra: Añade objetos 3D, cielo azul en 1 click, y embellece o
                ilumna automaticamente
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/es/releases/anade-objetos-3d-y-cielo-azul"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseJul2024Es}
                alt="Lanzamientos Pedra mes de Julio 2024 – Portada decorativa"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>JUNIO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/releases/renueva-espacios-desde-el-movil-con-ia"
              >
                Pedra: Renueva espacios desde el móvil, edita pies de foto y
                cambia las marcas de agua
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/es/releases/renueva-espacios-desde-el-movil-con-ia"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseJun2024Es}
                alt="Lanzamientos Pedra mes de Junio 2024 – Portada decorativa"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>MAYO 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/releases/vacia-habitacion-y-quita-objetos-con-ia"
              >
                Pedra: Vaciar Habitaciones y Quitar Objetos, Estilos de
                Renovación y Nueva Interfaz
              </Link>
            </h2>
            <div className="value-prop-text"></div>
          </div>
          <div className="picture">
            <Link
              to="/es/releases/vacia-habitacion-y-quita-objetos-con-ia"
              style={{ padding: "0px" }}
            >
              <img
                src={ReleaseMay2024Es}
                alt="Lanzamientos Pedra mes de Junio 2024 – Portada decorativa"
                className="hover-opacity"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReleasesEs;
