import React from "react";
import Article1Frame from "./Images/Article1Frame.png";
import Article2Frame from "./Images/Article2Frame.png";
import Article3Frame from "./Images/Article3Frame.png";
import Article4Frame from "./Images/Article4Frame.png";
import Article5Frame from "./Images/Article5Frame.jpeg";
import Article6Frame from "./Images/Article6Frame.jpeg";
import Article7Frame from "./Images/Article7Frame.jpg";
import Article8Frame from "./Images/Article8Frame.png";
import Article9Frame from "./Images/Article9Frame.png";
import { Link } from "react-router-dom";

function BlogEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container" style={{ marginTop: "100px" }}>
          <h2 className="title-container">Blog</h2>
          <div className="title-secondary">
            Bienvenido al blog de Pedra. <br />
            En este blog aprenderás como sacarle provecho la IA aplicada al
            mundo de inmobiliarias.
          </div>
        </div>
      </div>
      <br />

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>23 de Agosto, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/aumenta-valor-vivienda"
              >
                Aumenta el valor de la vivienda rápido y fácil
              </Link>
            </h2>
            <div className="value-prop-text">
              Te enseñamos cómo aumentar el valor de la vivienda de forma rápida
              y sencilla con varios consejos y tips para mejorar tu hogar antes
              de venderlo &#127969;
            </div>
          </div>
          <div className="picture">
            <img
              src={Article9Frame}
              alt="Aumenta valor vivienda – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>21 de Agosto, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/espacios-multifuncionales"
              >
                Espacios Multifuncionales ¿Qué es y cómo optimizar espacio?
              </Link>
            </h2>
            <div className="value-prop-text">
              Los espacios multifuncionales tienen el objetivo de aprovechar al
              máximo el espacio posible, usando una misma zona para diferentes
              funciones.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article8Frame}
              alt="Espacios mutltifuncionales – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>9 de Agosto, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/home-staging-ejemplos"
              >
                Home Staging Ejemplos: Antes y Después
              </Link>
            </h2>
            <div className="value-prop-text">
              ¿Estás buscando ejemplos de home staging de antes y después? Para
              que te puedas hacer una idea del poder de esta técnica, aquí te
              traemos algunos ejemplos de Home Staging para ti.
            </div>
          </div>
          <div className="picture">
            <img
              src={Article7Frame}
              alt="Cómo poner el cielo azul en tus fotografías de inmuebles – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>19 de Julio, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/como-poner-cielo-azul-fotografias-de-inmuebles"
              >
                Cómo Poner el Cielo Azul en tus Fotografías de Inmuebles
              </Link>
            </h2>
            <div className="value-prop-text">
              Descubre cómo poner el cielo azul y despejado en las fotografías
              de tus inmuebles
            </div>
          </div>
          <div className="picture">
            <img
              src={Article6Frame}
              alt="Cómo poner el cielo azul en tus fotografías de inmuebles – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>16 de Julio, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta"
              >
                Cómo Asegurar que la Foto de Portada de tu Anuncio Inmobiliario
                Acelere la Venta
              </Link>
            </h2>
            <div className="value-prop-text">
              Escoge y genera fotos de portada en tus anuncios inmobiliarios que
              capten la atención y te ayuden a vender
            </div>
          </div>
          <div className="picture">
            <img
              src={Article5Frame}
              alt="Cómo asegurar que la porta de tu anuncio inmobiliario acelere la venta – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>19 de Junio, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/vende-mas-propiedades-inmobiliarias-con-ia"
              >
                ¿Cómo Puede La IA Ayudar A Los Agentes Inmobiliarios A Vender?
              </Link>
            </h2>
            <div className="value-prop-text">
              Una explicación sobre qué es la IA y cómo puede ayudar a la venta
              de inmuebles
            </div>
          </div>
          <div className="picture">
            <img
              src={Article4Frame}
              alt="Cómo utilizar la IA para renovar casa – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>16 de Mayo, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                className="article-title-on-blog"
                to="/es/blog/renovar-casa"
              >
                Ideas Para Renovar Mi Casa
              </Link>
            </h2>
            <div className="value-prop-text">
              En este artículo explicamos cómo renovar tu casa sin obras y
              rápido
            </div>
          </div>
          <div className="picture">
            <img
              src={Article2Frame}
              alt="Cómo utilizar la IA para renovar casa – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>14 de Mayo, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                a="/es/blog/como-vender-casa"
                className="article-title-on-blog"
              >
                Cómo Vender Rápido Mi Casa
              </Link>
            </h2>
            <div className="value-prop-text">
              En este artículo explicamos cómo puedes vender de forma rápida tu
              casa con una serie de consejos
            </div>
          </div>
          <div className="picture">
            <img
              src={Article3Frame}
              alt="Cómo utilizar la IA para vender propiedades – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>4 de Abril, 2024</h5>
            <h2 className="article-title-on-blog">
              <Link
                to="/es/blog/como-utilizar-ia-para-vender-propiedades"
                className="article-title-on-blog"
              >
                Cómo Utilizar La IA Para Vender Propiedades Inmobiliarias
              </Link>
            </h2>
            <div className="value-prop-text">
              En este artículo explicamos cómo puedes utiliar la IA para generar
              imágenes que ayuden a vender tu propiedad
            </div>
          </div>
          <div className="picture">
            <img
              src={Article1Frame}
              alt="Cómo utilizar la IA para vender propiedades – Ilustración decorativa"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogEs;
