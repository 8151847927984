// Render.js
import React, { useState } from "react";
import CustomerLogos from "./CustomerLogos.js";

//Icons
import RedEyeIcon from "./Icons/RedEyeIcon";
import RedHammerIcon from "./Icons/RedHammerIcon";
import RedMultipleIcon from "./Icons/RedMultipleIcon";
import RedBrushIcon from "./Icons/RedBrushIcon";
import RedDownloadIcon from "./Icons/RedDownloadIcon";
import RedPlantIcon from "./Icons/RedPlantIcon";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";

//Images
import RenderHeader from "./Images/RenderHeader.webp";
import RenderFeature1 from "./Images/RenderFeature1.png";
import RenderFeature2 from "./Images/RenderFeature2.png";
import RenderFeature3 from "./Images/RenderFeature3.png";
import RenderFeature4 from "./Images/RenderFeature4.png";
import RenderFeature5 from "./Images/RenderFeature5.png";
import RenderFeature6 from "./Images/RenderFeature6.png";
import RenderStep1 from "./Images/RenderStep1.png";
import RenderStep2 from "./Images/RenderStep2.png";
import Soma from "./Images/Soma image.png";
import VictorLaviosa from "./Images/Victor Laviosa image.png";
import MariaJoseGilart from "./Images/Maria Jose Gilart image.png";
import EmanuelaCosma from "./Images/Emanuela Cosma image.png";
import DanieldelaMata from "./Images/Daniel de la Mata image.png";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function Render() {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);

  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">Renders with AI</h1>
            <div className="section-first-text">
              Use Pedra to generate professional renovation renders with
              artificial intelligence. Just upload your image and select a
              renovation option
            </div>
            <div className="section-first-button-container">
              <button
                className="button-small"
                onClick={redirectToPage}
                style={{
                  backgroundColor: "#EB5757",
                  border: "1px solid #EB5757",
                }}
              >
                Try Pedra <ArrowIcon />
              </button>
            </div>
            <div className="testimonials-on-header-container">
              <div className="testimonials-on-header-image-container">
                <img
                  className="testimonials-on-header-image"
                  src={VictorLaviosa}
                  alt="Victor Laviosa, Director of Photography at aProperties"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={Soma}
                  alt="Francisco Somacarrera, Real Estate Agent at Huspy"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={DanieldelaMata}
                  alt="Daniel de la Mata, CEO at Actívox"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={MariaJoseGilart}
                  alt="Maria José Gilart, CEO at Gilart Consultors"
                ></img>
                <img
                  className="testimonials-on-header-image"
                  src={EmanuelaCosma}
                  alt="Emanuela Cosma"
                ></img>
              </div>
              <div>
                <div className="testimonials-on-header-stars">★★★★★</div>
                <p className="testimonials-on-header-text">
                  5,000+ professionals already use Pedra
                </p>
              </div>
            </div>
          </div>
          <div className="header-picture">
            <img
              src={RenderHeader}
              alt="Tool To Make Renders with AI"
              width="600"
              height="400"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MORE THAN 5,000 REAL ESTATE PROFESSIONALS ALREADY TRUST PEDRA
          <CustomerLogos />
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">Beautiful renders in 1 click</h2>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedHammerIcon />
                <h2 className="feature-title">
                  Create renders from an image with AI
                </h2>
                <div className="feature-text">
                  Generate a professional render from a single image
                </div>
              </div>
              <img
                src={RenderFeature1}
                alt="Create Amazing Renders with AI"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedMultipleIcon />
                <h2 className="feature-title">
                  Generate multiple renders in one go
                </h2>
                <div className="feature-text">
                  You can also generate multiple renders at a time by selecting
                  them and choosing a renovation option
                </div>
              </div>
              <img
                src={RenderFeature2}
                alt="Create Many Renders At A Time – Software Screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedEyeIcon />
                <h2 className="feature-title">Select render styles</h2>
                <div className="feature-text">
                  Choose one of our various renders styles: minimalistic,
                  scandinavian, bohemian, etc.
                </div>
              </div>
              <img
                src={RenderFeature3}
                alt="Select Render Styles with Artificial Intelligence"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedDownloadIcon />
                <h2 className="feature-title">Download render in any format</h2>
                <div className="feature-text">
                  Download renders in PNG, JPG, or JPEG formats
                </div>
              </div>
              <img
                src={RenderFeature4}
                alt="Download Render In Any Format – Software Screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedPlantIcon />
                <h2 className="feature-title">Add any object with AI</h2>
                <div className="feature-text">
                  Add specific objects to rooms: plants, tables, etc.
                </div>
              </div>
              <img
                src={RenderFeature5}
                alt="Add Any Object – Software Screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <RedBrushIcon />
                <h2 className="feature-title">Remove objects with AI</h2>
                <div className="feature-text">
                  Remove parts of an image before or after rendering
                </div>
              </div>
              <img
                src={RenderFeature6}
                alt="Remove Objects – Software Screenshot"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <h2 className="title-container">
          How to make a renovation render with AI?
        </h2>
      </div>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={RenderStep1}
            alt="How To Upload A File – Diagram"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>

        <div className="description">
          <h5>STEP 1</h5>
          <h2>Create a new project and upload one or more images</h2>
        </div>
      </section>

      <section className="project project-alt">
        <div className="picture">
          <img
            src={RenderStep2}
            alt="How To Create Render – Diagram"
            width="600"
            height="400"
            style={{ width: "100%", height: "auto" }}
          />
        </div>

        <div className="description">
          <h5>STEP 2</h5>
          <h2>Select one of the renovation options</h2>
          <div className="value-prop-text">
            The renovation options usually take 15 seconds. You can generate
            various renovations at the same time.
          </div>
        </div>
      </section>

      <div className="long-break"> </div>
      <div className="centered-heading">
        <button
          className="button"
          onClick={redirectToPage}
          style={{ backgroundColor: "#EB5757", border: "1px solid #EB5757" }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#E13A3A"; // Darker background color on hover
            e.target.style.borderColor = "#E13A3A"; // Darker border color on hover
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "#EB5757"; // Restore original background color on mouse out
            e.target.style.borderColor = "#EB5757"; // Restore original border color on mouse out
          }}
        >
          Try Pedra <ArrowIcon />
        </button>
      </div>
      <br></br>

      <div className="section-one-column-left">
        <div className="title-container">Questions & answers</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">What is an AI render?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                An AI render, in the context of architectural design and
                visualization, refers to the application of artificial
                intelligence (AI) to generate high-quality rendered images of
                architectural spaces. Artificial intelligence is used to
                re-create spaces based on inputs images, allowing for realistic
                and precise results.
                <br />
                <br />
                AI allows for faster results than traditional rendering methods.
                Older methods involved manually re-creating 3D models of the
                rooms and processing images to create stunning renders. With AI
                this is now different. We can start from images to create new
                visuals based on the original image and a newly applied style.
                <br />
                <br />
                Artificial intelligence in rendering of real estate images not
                only saves time but provides a more flexible way to imagine
                spaces for the realtor, the owners and the buyers. The
                technology helps visualize rapidly the potential of spaces in a
                way never seen before.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                What are the advantages of using AI renders?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                <h4>Enhanced quality:</h4>AI renders can produce visually
                stunning results with a high degree of realism. AI algorithms
                can simulate lighting effects, shadows, and textures more
                accurately than traditional methods.
                <br />
                <br />
                <h4>Rendering time efficiency:</h4>Artificial intelligence can
                speed up the rendering process by optimizing the calculations
                needed to generate an image. This can significantly reduce
                rendering times, which is especially beneficial in projects with
                tight deadlines.
                <br />
                <br />
                <h4>Automation of repetitive tasks:</h4>AI can automate certain
                tasks within the rendering process. This frees up time for
                artists and designers to focus on creative and conceptual
                aspects.
                <br />
                <br />
                <h4>Personalization and adaptability:</h4>AI algorithms can
                learn from specific patterns and preferences, allowing for the
                customization of renders according to project needs or client
                preferences. This can lead to more tailored and satisfying
                results.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">
                How does AI-powered rendering work?
              </h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                <h4>Data capture and model training:</h4>
                <li>
                  The process begins with the collection of input data, which
                  may include reference images, 3D models, textures, and other
                  scene-related elements.
                </li>
                <li>
                  These data are used to train an AI model, usually a neural
                  network, which will learn to map the features of the input to
                  the desired output, i.e. the rendered image.
                </li>
                <br />
                <br />
                <h4>Scene processing:</h4>
                <li>
                  Once the model is trained, it is used to process the scene to
                  be rendered. This involves generating pixels for each point of
                  the final image.
                </li>
                <li>
                  During this process, the AI model may take into account a
                  variety of factors such as lighting, materials, shadows,
                  textures, and the geometry of the scene.
                </li>
                <br />
                <br />
                <h4>Generation of the rendered image:</h4>
                <li>
                  The AI model generates the rendered image pixel by pixel,
                  using the information learned during training to make
                  decisions about how to render each part of the scene.
                </li>
                <li>
                  This may include applying global illumination effects,
                  shading, reflections, refractions, and other visual effects to
                  enhance the quality and realism of the final image.
                </li>
                <br />
                <br />
                <h4>Refinement and post-processing:</h4>
                <li>
                  Once the initial image is generated, it may undergo a process
                  of refinement and post-processing to further improve its
                  quality.
                </li>
                <li>
                  This may include correcting visual artifacts, applying
                  post-processing effects such as blur or glow, and other
                  techniques to enhance the overall aesthetics of the image.
                </li>
                <br />
                <br />
                <h4>Iteration and adjustment:</h4>
                <li>
                  Depending on the results obtained, the AI rendering process
                  may involve additional iterations, where model parameters are
                  adjusted and results are refined until the desired level of
                  quality and realism is achieved.
                </li>
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">How much does Pedra cost?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra costs 29€ plus tax per month.
                <br />
                <br />
                It's a monthly subscription, therefore you can cancel your
                subscription if you don't need it anymore.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Render;
